@import "variables";
@import "global";
@import "./../styles/functions";

.pricelist-card{
    @include media-breakpoint-down (lg) {
        .search-bar{
            height: auto !important;
            padding: 0.5rem 1rem !important;
        }
     }
}

.pricelists{
    .myaccount-header__title{
        h3{
            font-size: 1.2rem;
            color: $orange;
        }
    }
    .card-dropdown{
        display: none;
        @include media-breakpoint-down (lg) {
           display: block;
        }
    }
    .pricelist-title{
        font-size: 1.8rem;
        text-transform: capitalize;

    }
    .account-nav-outer{
        @include media-breakpoint-down (lg) {
            display: none;
         }
        h3{
            padding-bottom: calcRem(15px) !important;
            color: $orange;
            font-size: 1.3rem;
            font-weight: normal;
        }
    }
    table{
        tr{
            td:first-child,
            th:first-child{
                text-align: left;
            }
        }
  
    }

    .card-searchbox__results{
        overflow-y: auto;
        height: 600px;
    }
    

    .pricelist-card{
        border-radius: 0;
        border:0 !important;
        margin-bottom: calcRem(50px);
    }
    .card-searchbox{
        margin-bottom: 30px;
    }
    .myaccount__content__nav{     
        ul{
            margin-top:0;
            padding-top:0;
            margin-left: 0;
            padding-left: 0;
            li{
                text-transform: capitalize;
                font-size: 1rem;
                a{
                    position: relative;
                    display: block;
                    font-weight: 400;
                    color: $darkgrey;
                    text-decoration: none;
                    &:hover, 
                    &.active{
                        color: $orange;
                        &::before{
                            display: block;
                        }
                    }
                    &::before{
                        content : '';
                        height: 2px;
                        width:calcRem(15px);
                        background: $orange;
                        top:50%;
                        transform: translateY(-50%);
                        left: -25px;
                        position: absolute;
                        display: none;
                    }
                }
            }
        }
    }
}