.password-reset{
    > div{
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        .form-control{
 
        }
        .row{
            width:50%;
            &:last-child{
                width: 100%;
                padding-left: 15px;
                .lw-button{
                    width: auto;
                }
            }
        }
    }
}