@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "variables";
@import "functions";

.App {
  font-family: $fontRoboto;
  overflow-x: hidden;
}


body {
  font-family: "Roboto", sans-serif;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

a {
  cursor: pointer;
}

.alert-warning{
  background-color: $presold;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.text-orange {
  color: $orange;
}

svg {
  .text-orange {
    fill: $orange;
  }
}

.text-red {
  color: $red;
}

.text-small {
  font-size: $fontSizeExtraSmall;
}

.text-grey-tiny {
  color: $bordergrey;
  font-weight: $bold;
  font-size: calcRem(12px);
}

.font-size-title {
  font-size: $fontSizeMedium;
}

.font-bold {
  font-weight: $bold;
}

.font-small {
  font-size: $fontSizeExtraSmall !important;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0 !important;
  padding: 0 !important;
}

.no-padding {
  padding: 0;
}

.no-decoration {
  text-decoration: none;
}

.container-fluid {
  padding: 0 !important;
}

li {
  list-style: none;
}

.sr-only {
  display: none;
}


.green-circle {
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: $green;
  z-index: 999;
  margin-right: calcRem(10px);
  margin-top: calcRem(5px);
}

.red-circle {
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: $red;
  z-index: 999;
  margin-right: calcRem(10px);
  margin-top: calcRem(5px);
}

.amber-circle {
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: $presoldOrange;
  z-index: 999;
  margin-right: calcRem(10px);
  margin-top: calcRem(5px);
}

.bold {
  font-weight: 500;
}

.orange {
  color: $presoldOrange;
}

#rcc-decline-button {
  background: $red !important;
  border-radius: 10px !important;
  padding: 5px 10px !important;
  font-size: 14px !important;
}

.text-capitalise {
  text-transform: capitalize;
}

.availble-delivery {
  margin-top: 10px;
  border-radius: 15px;
  background-color: $deliverygrey;
  font-size: 0.85rem;
  padding: 8px 15px;
  color: $textgrey;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: flex-start;

  img {
    margin-right: 6px;
  }
}

.mr-2 {
  margin-right: 6px;
}

.mr-3 {
  margin-right: calcRem(10px);
}

.text-black {
  color: $black;
}

.text-grey {
  color: $textgrey;
}

.text-dark-grey {
  color: $darkgrey;
}

.underline {
  text-decoration: underline;
}

.back-icon {
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
  width: 3rem;
  cursor: pointer;
  z-index: 2;
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.hide-desktop {
  display: none;
}

@include media-breakpoint-down(md) {
  .modal.show .modal-dialog {
    margin-top: 10% !important;
    max-height: 80% !important;
  }

  .mob-padd-top {
    padding-top: calcRem(15px);
  }

  .hide-mob {
    display: none !important;
  }

  .hide-desktop {
    display: block;
  }
}

.breadcumb {
  background: $lightergrey;
  padding: calcRem(5px) calcRem(20px);

  @include media-breakpoint-down(sm) {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    li {
      list-style: none;
      display: inline-block;
      text-transform: capitalize;

      span,
      a {
        color: $darkgrey;
        text-decoration: none;
        display: block;
        padding: 0 calcRem(5px)
      }
    }
  }
}

.roundcheck {
  fill: $orange;
}

.myaccount-header{
  padding-top:2.5rem;
  .container{
    display: flex;
    justify-content: space-between;
  }
}



.pricelists{
  .pricelist-back-link{
    cursor: pointer;
  }
}
@include media-breakpoint-down( md ){
  .pricelists{
    .myaccount-header {
        height: auto;
        margin-bottom: 0;
    }
    .nav-link {
        display: block;
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
        font-size: var(--bs-nav-link-font-size);
        font-weight: var(--bs-nav-link-font-weight);
        color: var(--bs-nav-link-color);
        text-decoration: none;
        background: none;
        border: 0;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    }
  }

}

.modal{
  z-index: 999999 !important;
}
.modal-backdrop{
  z-index: 99998 !important;
}