@import "variables";
@import "global";
@import "./../styles/functions";


.contact-outer{
  background-color: $midgrey;
  background: linear-gradient(to left, #fff 50%, $midgrey 50%);

}

.contact {
  color: $darkgrey;
  

  @include media-breakpoint-down(md) {
    padding-right: 0;
    padding-left: 0;  
  }

  &__left {

    background-color: $midgrey;
    padding: calcRem(80px) !important;
    padding-left: calcRem(40px) !important;

    @include media-breakpoint-down(md) {
      padding-left: calcRem(40px) !important;
      padding-right: calcRem(40px);
    }

    @include media-breakpoint-down(sm) {
      padding: calcRem(40px) !important;
    }
    &__title {
      font-size: calcRem(36px);
      font-weight: $bold;
      padding-bottom: calcRem(30px) !important;
      padding-left: calc(var(--bs-gutter-x) * .5);
    }
    &__subtitle {
      font-size: calcRem(20px);
      font-weight: $bold;
      padding-bottom: calcRem(10px) !important;
    }
    &__text {
      font-size: calcRem(15px);
      padding-bottom: calcRem(40px) !important;
      color: $textgrey;
    }
  }
  &__right {
    background-color: $white;
    padding-top: calcRem(70px);
    padding-left: calcRem(40px) !important;

    @include media-breakpoint-down(md) {
      padding-left: calcRem(40px) !important;
      padding-right: calcRem(40px);
    }

    &__order {
      padding-top: calcRem(30px);
    }
    &__title {
      font-size: calcRem(18px);
      font-weight: $bold;
      padding-bottom: calcRem(20px) !important;
    }
  }
  &__right h3 {
    font-size: calcRem(22px);
  }
}

.form-control {
  height: calcRem(60px);
  border-radius: calcRem(10px);
}

.form-enquiry {
  width: 100%;
  min-height: calcRem(139px);
  border-color: $orange;
  border-radius: calcRem(10px);
  padding: calcRem(20px);
}

input:focus {
  outline: none !important;
}

.input-title-pb {
  margin-bottom: calcRem(10px) !important;
}

// Helpers

.pt-10 {
  padding-top: calcRem(10px);
}

.pt-20 {
  padding-top: calcRem(20px);
}

.pt-30 {
  padding-top: calcRem(30px);
}

.pt-40 {
  padding-top: calcRem(40px);
}

.pt-50 {
  padding-top: calcRem(50px);
}

.red-text {
  color: $red;
}
