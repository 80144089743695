@import "variables";
@import "global";
@import "./../styles/functions";

.grey-container {
  background-color: $lightergrey;
}

.myaccount {
  padding-top: calcRem(30px);

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calcRem(40px);
  }
  &__content {
    &__nav {

    }
    &__body {
      &__top {
        display: flex;
        flex: 1;
        align-items: center;
        &__left {
          &__status {
            display: flex;
            flex: 1;
            justify-content: space-between;
            font-weight: $bold;

            .active {
              border-bottom: $orange;
            }
          }
        }
        &__right {
          display: flex;
          justify-content: flex-end;
          &__search {
            max-width: calcRem(407px);
            width: 100%;
            height: calcRem(60px);
            border: 1px solid $darkgrey;
            background-color: $white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: calcRem(30px);
            &__text {
              padding-left: calcRem(30px);
            }
            &__button {
              padding-right: calcRem(2.5px);
            }
          }
        }
      }
      &__popup {
        width: 100%;
        background-color: $white;
        border-bottom: 4px solid $orange;
        display: flex;
        align-items: center;
        &__warning {
          padding-left: calcRem(10px);
        }
        &__text {
          padding-left: calcRem(15px);
        }
      }
      &__count {
        display: flex;
        justify-content: space-between;
        padding-top: calcRem(40px);
        padding-bottom: calcRem(30px);
      }
    }
  }
}

.contact-bottom {
  padding: calcRem(80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title h3 {
    font-size: calcRem(23px) !important;
    font-weight: $bold;
    padding-bottom: calcRem(12px) !important;
    text-align: center;
  }
  &__desc p {
    padding-bottom: calcRem(30px) !important;
    text-align: center;
  }
  &__cta {
    display: flex;
    &__left {
      padding-right: calcRem(12px);
    }
  }
}
