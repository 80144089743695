@import "variables";
@import "global";
@import "./../styles/functions";

.subtitle {
  color: $darkgrey;
  font-size: calcRem(26px);
  font-weight: $bold;
}

.hero-categories-container {
  min-height: 0;
}

.center-align {
  @include media-breakpoint-down (md) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.hero-categories-container {
  padding-right: calcRem(60px) !important;
  padding-left: calcRem(60px) !important;

  @include media-breakpoint-down(md) {
    padding-right: calcRem(20px) !important;
    padding-left: calcRem(20px) !important;
  }

  .supertitle {
    margin-bottom: calcRem(15px);

    h4 {
      text-align: center;
      font-size: calcRem(18px);
      text-transform: uppercase;
    }
  }


}

@media (max-width: 991px) {
  .hero-categories-container {
    padding-right: calcRem(20px) !important;
    padding-left: calcRem(20px) !important;
  }
}

.hero-section {
  width: 100%;
  min-height: calcRem(470px);
  // background-color: $lightergrey;
  border-bottom-right-radius: calcRem(50px);
  padding-top: calcRem(30px);

  // padding: calcRem(70px) calcRem(90px);
  @include media-breakpoint-down(md) {
    min-height: calcRem(350px);
  }

  @include media-breakpoint-down(sm) {
    min-height: calcRem(400px);
    min-height: 0;

    &__title__header {
      text-align: center;
    }
  }

  &__title {
    padding-bottom: calcRem(32px);

    &__header {
      font-size: calcRem(35px);
      font-weight: $bold;
    }

    &__desc {
      display: flex;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      &__link {
        margin-left: calcRem(8px);
        color: $orange;
      }
    }
  }

  &__search {
    display: flex;
    align-items: center;
    padding-bottom: calcRem(20px);

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &__control {
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        display: none;
      }

      @include media-breakpoint-down(md) {
        padding-top: calcRem(20px);
      }
    }
  }
}

.categories {
  margin-top: calcRem(-180px);

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  .category-counts {
    float: right;
  }

  &__title {
    padding-bottom: calcRem(22px);

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    @include media-breakpoint-down(md) {
      padding-top: calcRem(15px);
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }

  &__row {
    display: block;
    margin: 0 auto;
  }

  &-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calcRem(40px);
    margin-bottom: calcRem(40px);
  }
}

.activities {
  padding-top: calcRem(80px);
  position: relative;
  margin-bottom: calcRem(30px);



  .supertitle {
    font-size: calcRem(16px);
    margin-bottom: calcRem(15px);
  }

  &__latest {
    padding-right: calcRem(50px);

    @include media-breakpoint-down(lg) {
      padding-left: calcRem(20px);
    }

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }

  &-details {
    color: $white;
    padding: 60px !important;
    min-height: calcRem(621px);
    background-color: $darkgrey;
    border-bottom-left-radius: calcRem(68px);
  }

  &-details h3 {
    color: $white;
  }

  &-details p {
    font-size: calcRem(17px);
    padding: 0;
    margin: 0;
  }
}

.payments {
  margin-top: calcRem(80px);
  padding: calcRem(80px);

  @include media-breakpoint-down(lg) {
    margin-top: calcRem(20px);
    padding: calcRem(50px);
    display: flex;
    align-items: center;
  }
}

.activities-full {
  max-width: 1320px;
  padding-right: 0 calcRem(60px) 0;
  margin: calcRem(50px) auto calcRem(30px);
  display: flex;
  align-items: flex-start;

  @include media-breakpoint-down(xl) {
    max-width: 90%;
  }
   
  @include media-breakpoint-down(md) {
    max-width: 90%;
      flex-wrap: wrap;
  }

  .activities-full-label {
    width: 30%;

        
    @include media-breakpoint-down(sm) {
      width:100%;
    }

    .activities-cta {
      margin-top: calcRem(100px);
                 
      @include media-breakpoint-down(sm) {
        margin-top: calcRem(20px);
      }

    }

    .supertitle {
      font-size: calcRem(16px);
      margin-bottom: calcRem(15px) !important;
    }
  }

  .activities-full-list {
    width: 70%;

            
    @include media-breakpoint-down(sm) {
      width:100%;
    }

    height: 380px;
    overflow: hidden;
    overflow-y: auto;
  }

}