@import "variables";
@import "global";
@import "./../styles/functions";


.search {
  padding-top: calcRem(30px);
  padding-bottom: calcRem(30px);
  @include media-breakpoint-down(md) {
    display: none;
  }
  &.page-search{
    @include media-breakpoint-down(md) {
      display: block;
 
    }
  }
}
.search-row {
  padding-top: calcRem(30px);

  @include media-breakpoint-down(md) {
    > div{
      justify-content: center;
    }
  }

  .lw-pagination{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__control {
      &:hover {
        cursor: pointer;
      }
    }
    &__pill {
      padding: calcRem(10px) calcRem(18px);
      &:hover {
        cursor: pointer;
      }
        
      @include media-breakpoint-down(md) {
        &:not(.active-pill){
          display: none;;
        }
      }
    }

  
  .disabled {
      color: #979797;
    }

    .active-pill {
      color: $white;
      background-color: $orange;
      border-radius: 50%;
    }
  }
}
