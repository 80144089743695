@import "variables";
@import "functions";


.page-container {
  min-width: 100vw;
  min-height: 100vh;
  //background-image: url("../assets/images/login-bg.png");
  //background-size: cover;

  &.login-outer{
    @include media-breakpoint-down(md){
      padding: calcRem(130px) calcRem(50px);
      .login-img-column{
        display: none !important
      }
    }

  }
  

  .col-lg-6{
    position: relative;    
    height: 100%;
  }
  .sign-in-to{
    color: $textgrey;
  }
  .row{
    align-items: center;
  }
  .mt-6{
    margin-top:calcRem(60px);
  }
  .img-rotator{
    width:100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  img{
    width:calcRem(220px);
  }
  label{
    font-size: 0.8rem;
    color: $textgrey;
  }
  h3{
    font-size: 1.6rem;
  }

  .error-message-alert{
    padding: calcRem(20px) calcRem(20px) calcRem(20px) calcRem(45px);
    position: relative;
    border-radius: calcRem(8px);
    background: #FFF4F4;
    color: #C84342;
    svg{
      position: absolute;
      left: calcRem(15px);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .login-container {
    width: 100%;
    max-width: 500px;
    margin:auto;
    background-color: $white;
    padding: calcRem(0px) calcRem(0);
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    .forgot-password-button{
      text-align: right;
      .lw-button{
        padding-right: 0;
        width:auto;
      }

    }
    .input-container{
      padding-right: 0 !important;
      margin-right: 0 !important;
      width:100% !important;
    }

    input[type="text"],
    input[type="password"]{
      border-radius: calcRem(8px);
      border-color: $lightgrey !important;
    }


    .input-container {
      width: 90%;
    }
  }
  .footer-bottom__copyright{

    font-size: 0.8rem;
    margin-top:calcRem(50px);
    a{
      color: $textgrey;
      text-decoration: none;
    }
  }
}

.login-outer{
  & + .CookieConsent{
    @include media-breakpoint-down(md) {
        bottom:0 !important;    
    }
  }
}



.rotator-inner {
  position: absolute;
  bottom:30px;
  right:30px;
  svg, input {
    display: block;
    margin: 2px;
  }
  
  svg {
    width: 60px;
    height:60px;
    stroke: #FFF;
    stroke-width: 3px;
    fill: transparent;

  }
  .graph-counter{
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 14px;
    color: #FFF;
  }
}




@keyframes countdown {
  from {
    stroke-dashoffset: 113px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}