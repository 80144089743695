@import "variables";
@import "global";
@import "./../styles/functions";

.medium-grey-text {
  font-weight: 700;
  color: $darkgrey;
}
.loading-modal{
  padding:calcRem(30px);
}

.confirmation {
  padding-top: calcRem(40px);
  padding-left: calcRem(100px);
  padding-right: calcRem(100px);
  background-color: $lightergrey;

  @include media-breakpoint-down(md) {
    padding-top: calcRem(20px);
    padding-left: calcRem(20px);
  }
  @include media-breakpoint-down(sm) {
    padding: calcRem(25px);
  }
  &__title{
    padding-bottom: calcRem(30px);
    @include media-breakpoint-down(md) {
      padding-bottom: calcRem(10px);
    }
  }
  &__title h4 {
    color: $darkgrey;
    font-size: calcRem(36px);
    font-weight: $bold;
    padding-bottom: 0 !important;
    margin-right: calcRem(30px);
 
  }
  &__info {
    display: flex;
    justify-content: space-between;
    padding-top: calcRem(30px);
  }
  &__line {
    width: 100%;
    height: calcRem(1px);
    background-color: $lightgrey;
  }
  
}

.order-total {
  display: flex;
  flex-direction: column;
  padding-top: calcRem(30px);
  padding-bottom: calcRem(80px);
  color: $darkgrey;
  font-size: calcRem(14px);
  @include media-breakpoint-down(md) {
    padding-left: calcRem(20px);
    paddingright: calcRem(20px);
  }
  &__column {
    padding-top: calcRem(30px);
    padding-left: calcRem(12px);
    @include media-breakpoint-down(sm) {
      padding-left: calcRem(0px);
    }
    &__subtotal {
      justify-content: space-between;
      
    }
    &__vat {
      justify-content: space-between;
    }
    &__total {
      justify-content: space-between;
      font-weight: $bold;
    }
  }
}

