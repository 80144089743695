@import "./../../styles/variables";
@import "./../../styles/functions"; 

.menu-link {
  text-decoration: none;
  color: $darkgrey;
}

.active {
  color: $orange !important;
}

.menu-link:hover {
  color: $orange !important;
}
.delete-account-link{
  color: $darkgrey !important;
  text-decoration: underline;
}
.account-nav-outer{
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  position: relative;
  ul:last-child{
 
    margin-top:100px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    ul{
      margin-top:0;
      margin-bottom: 0;
      padding-bottom: 0;
      margin-left: 0;
      padding-left: 0;
    }
    ul:last-child{
      position: static;
      padding-bottom: 2rem;
    }
  
  }
}
// .link-items {
//   display: flex;
//   &__bar {
//     width: calcRem(20px);
//     height: calcRem(3px);
//     background-color: $orange;
//     margin-right: calcRem(5px);

//   }
// }
