@import "./../../styles/variables";
@import "./../../styles/functions";
.please-call{
  width:90%;
  margin-left: auto;
  margin-right: auto;
  display: table;
  div{
    width: 100%;
    font-size: 0.9rem;
    padding: calcRem(5px) calcRem(10px);
    background-color: $pleasecall;
    border-radius: calcRem(8px);
    border:1px solid $pleasecall;
  }
  span{
    display: inline-block;
    padding-left: calcRem(10px);
    color: $orange;
  }
  .please-call-icon{
    width:calcRem(15px);
    margin-right: calcRem(10px);
    display: inline-block;
    svg{
      width: calcRem(20px);
      fill: $orange; 
    }
  }
}