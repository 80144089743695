@import "./../../styles/variables";
@import "./../../styles/functions";

.checkout_item {
  background-color: $white;
  padding: calcRem(24px);
  margin-bottom: calcRem(40px);
  -webkit-box-shadow: 2px 12px 8px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 2px 12px 8px 5px rgba(0, 0, 0, 0.03);

  .alert{
    font-size: 0.9rem;
    position: relative;
    color:$textgrey !important;
    padding-left: calcRem(60px) !important;
    img{
      position: absolute;            
      top: calcRem(18px);
      left: calcRem(24px);
      width:calcRem(24px);
      height: calcRem(24px);
    }
  }

  &__inner{
    display: flex;
    justify-content: space-between;

  }

  .checkout_item_img {
    padding-right: calcRem(10px);
    width: calcRem(98px);

    img {
      width: 100%;
      height: auto;
      border-radius: 20px;
    }
  }

  .checkout_item_content {
    width: 85%;
    position: relative;

    button,
    span,
    div,
    a {
      font-size: calcRem(14px);
    }

    .checkout_item_row_1,
    .checkout_item_row_2 {
      display: flex;
      justify-content: space-between;
      align-items: top;
      width: 100%;

      .checkout-item-remove_action{
        min-width: calcRem(98px);
      }
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }

      @include media-breakpoint-down(sm) {
        .checkout-item-remove_action{
          position: absolute;
          top:0;
          width:auto;
          right:0;
          text-align: right;
        }
      }

      .checkout_item_descr {
        width: 40%;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        padding-right: 10px;

        b {
          font-size: calcRem(14px);
        }
      }

      .checkout_item_detail {
        width: 60%;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        .checkout_item_detail_row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          .w-100{
            justify-content: space-between;
          }

          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
          }

          >div {
            @include media-breakpoint-down(sm) {
              width: 50%;
            }
          }
        }
      }
    }

    .checkout_item_row_2 {
      margin-top: calcRem(10px);
      > div{
        @include media-breakpoint-down(sm) {
          padding-bottom: calcRem(5px);
        }
      }

      .checkout_item_detail_row {
        justify-content: space-between;
        .w-100{
          justify-content: space-between;
        }
        .dropdown {
          border: 0 !important;
          padding: 0 !important;
          color: $orange !important;
        }

        .dropdown-toggle {
          border: 0 !important;
          padding: 0 !important;
          color: $orange !important;
          text-decoration: underline;
          height: auto !important;
          color: $orange;

          &:after {
            display: none;
          }
        }

        .shipping_method {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;

            >div {
              width: 100%;
            }
          }

          label {
            margin-right: 8px;

            input {
              margin-right: 8px;
              position: relative;
              top: 3px;
            }
          }
        }

      }

    }


  }
}

@include media-breakpoint-down(sm) {
  .checkout-item-bottom__sqm{
    width:50%;
  }
}