@import "./../../styles/variables";
@import "./../../styles/functions";

.dashbanner--item {
    position: relative;
    opacity: 0;
    height: 0;

    &.active {
        height: auto;
        opacity: 1;
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .dasboardbanner--image {
        // padding-bottom: 25%;
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center center;

        img {
            width: 100%;
            height: auto;
        }

        @include media-breakpoint-down (md) {
            display: none;
        }
    }

    .dasboardbanner--image__mob {
        // padding-bottom: 100%;
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center center;
        display: none;

        img {
            width: 100%;
            height: auto;
        }

        @include media-breakpoint-down (md) {
            display: block;
        }
    }
}