@import "variables";
@import "functions";


@keyframes l4 {to{transform: rotate(1turn)}}
.thankyou{
    background: $orange;
    background: linear-gradient(180deg, $orange 0%, $orange 20%, #F9F9F9 20%);
    min-height: 100vh;

    .the-loader{
        /* HTML: <div class="loader"></div> */
        text-align: center;
        display: flex;
        justify-content: center;
        padding:15px 0 0 0;
        .loader {
            width: 50px;
            --b: 8px; 
            aspect-ratio: 1;
            border-radius: 50%;
            padding: 1px;
            background: conic-gradient(#0000 10%,$orange) content-box;
            -webkit-mask:
            repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
            radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
            -webkit-mask-composite: destination-in;
                    mask-composite: intersect;
            animation:l4 1s infinite steps(10);
        }
    }
    .action-buttons{
        > div:first-child{
            padding-right: calcRem(5px);
            & + div{
                padding-left: calcRem(5px);
            }
        }
    }
    .payit-branding{
        padding:calcRem(30px);
        text-align: center;
        img{
            width: 100%;
            max-width: 600px;
            margin:auto;
        }
        
    }
    .order-number-row + div{
        padding-top:calcRem(15px);
    }
    .thankyou-header{
        padding:calcRem(40px);
        text-align: center;
        font-size: 1.4rem;
        .check-circle{
            width:20px;
            svg{
                width: 20px;
                margin-right: 5px;
            }
            fill: #FFF;
        }
        .cross-circle{
            width:20px;
            svg{
                width: 20px;
                margin-right: 5px;
            }
            fill: red;
        }
    }


    .check-square{
        svg{
            width: 20px;
            margin-right: 10px;
            fill: $orange;
        }
    }
    .check-circle-green{
        svg{
            width: 20px;
            margin-right: 5px;
            fill: green;
        }
    }
    .thankyou-card{
        background: #FFF;;
        margin-bottom: 50px;
    }
    .thankyou-card-header{
        padding:calcRem(30px);
        text-align: center;
        border-bottom:1px solid $orange;
    }
    .thankyou-card-body{
        padding:calcRem(30px);
    }
    .order-summary-title{
        text-align: center;
        font-size: 1rem;
        font-weight: normal;
    }
    .order-summary-subtitle{
        text-align: center;
        font-size: 1.3rem;
    }
    .thank-you-total{
        font-size: 1.1rem;
        font-weight: 500;
    }
    .thankyou-table{
        display: table;
        width: auto;
        margin:auto;
        width:400px;
        .thankyou-table__row{
            padding:5px 0 ;
            display: table;
            width: 100%;
            table-layout: fixed;
            >div{
                display: table-cell;
            }
            >div:first-child{
                width:70%;
            }

        }
    }
}