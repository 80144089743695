@import "variables";
@import "global";
@import "./../styles/functions";

.half-grey-container{
    background: $lightergrey;   
    background: linear-gradient(180deg, $lightergrey 50%, rgba(255,255,255,1) 50%);
    padding: 2rem 0 3rem 0;
}
.white-container{
    padding:2rem 4rem;
    background: #FFF;
    max-width: 800px;
    margin:auto;
}
.delete-account__form--header{
    justify-content: center !important;
    padding-bottom: 1rem;
}
.myaccount-header__title{
    text-align: center;
}
.delete-account__form--title{
    font-size: 1.4rem;
    text-align: center;
    padding-bottom: 1rem !important;
}
.delete-account__form--summary{
    font-size: 0.9rem;
    text-align: center;
    padding-bottom: 1.5rem !important;
}
.delete-account__form{
    .dropdown{
        border-radius: 0 !important;
        color:#000 !important;
        border:1px solid $lightgrey !important;
        text-align: left !important;
        width:100%;
        background: $midgrey !important;
              
        *{
            background: $midgrey !important;
        }
    }
    .dropdown button{
        border-radius: 0 !important;
        color:#000 !important;
        text-align: left !important;
        width:100%;
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
    input[type="password"],
    input[type="text"]{
        border-radius: 0 !important;
        color:#000 !important;
        border:1px solid $lightgrey !important;
        text-align: left !important;
        width:100%;
    }
    button{
        width:auto;
        margin: auto;
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}