@import "variables";
@import "global";
@import "./../styles/functions";

.filter-caret {
    cursor: pointer;
}

.sentence-case {
    text-transform: capitalize;
}

.search {
    padding-top: calcRem(30px);
    padding-bottom: calcRem(30px);

    @include media-breakpoint-down(md) {
        display: none;
    }

    &.page-search {
        @include media-breakpoint-down(md) {
            display: block;

        }
    }
}

.clearance-outer {
    .search-row {
        padding-top: calcRem(30px);

        .catalogueItem {
            min-height: 20rem;
        }

        .catalogueItem__img,
        .catalogueItem__img img {
            height: 19rem !important;
            min-height: 19rem !important;
            object-fit: cover !important;
        }




        @include media-breakpoint-down(md) {
            >div {
                justify-content: center;
            }

        }

        @include media-breakpoint-down(md) {

            .catalogueItem__img,
            .catalogueItem__img img {
                height: 14rem !important;
                min-height: 14rem !important;
                object-fit: cover !important;
            }
        }



        .lw-pagination {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__control {
                &:hover {
                    cursor: pointer;
                }
            }

            &__pill {
                padding: calcRem(10px) calcRem(18px);

                &:hover {
                    cursor: pointer;
                }

                @include media-breakpoint-down(md) {
                    &:not(.active-pill) {
                        display: none;
                        ;
                    }
                }
            }


            .disabled {
                color: #979797;
            }

            .active-pill {
                color: $white;
                background-color: $orange;
                border-radius: 50%;
            }
        }
    }
}

.whole-filter-collapse {
    margin-right: calcRem(30px);

    h4 {
        color: $darkgrey;
    }
}

.filter-collapse {
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: calcRem(20px);
    margin-bottom: calcRem(20px);

    .filter-collapse-heading {
        font-weight: 500;
        font-size: 1rem;
        margin-bottom: calcRem(10px);
        cursor: pointer;
        color: $darkgrey;

        >div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .child-filter-caret {
                width: 10px;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

    }

    .filter-collapse-body {
        display: none;

        &.toggle-active {
            display: block;
        }

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .filter-collapse-row {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: calcRem(5px);

        &:last-child {
            margin-bottom: 0;
        }

        >span:first-child {
            width: calcRem(25px);

            &+span {
                color: $textgrey;
            }
        }
    }
}

.filter-caret {
    display: none;
}

.c-hide-desktop {
    display: none !important;
}

@include media-breakpoint-down(md) {
    .filter-caret {
        display: block;
        width: calcRem(15px);
    }

    .whole-filter-collapse {

        h4 {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }

        .filter-collapse {
            display: none;
        }

        .active-filters {
            display: block !important;
        }
    }

    .hide-mobile {
        display: none !important;
    }

    .c-hide-desktop {
        display: block !important;
    }

    .whole-filter-collapse {
        border-bottom: 1px solid $lightgrey;
    }

    .search-row {
        .col-md-6 {
            width: 50% !important;
        }

        .catalogueItem {
            min-height: 19rem !important;
        }

        .catalogueItem__img img {
            height: 16rem;
        }
    }

    .col-md-9 {
        .container {
            overflow: hidden;
        }
    }
}

.whole-filter-collapse {}

.price-filters {

    .filter-collapse-heading {
        font-weight: 500;
        font-size: 1.1rem;
        margin-bottom: calcRem(10px);
        cursor: pointer;
        color: $darkgrey;
    }

    padding-bottom: calcRem(20px);

    .price-filters-content {
        flex-wrap: wrap;

        .price-filters__pill {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            white-space: nowrap;
            margin-bottom: calcRem(10px);
            margin-right: calcRem(10px);
            padding: calcRem(5px) calcRem(10px) calcRem(5px) calcRem(10px);
            border-radius: calcRem(20px);
            background: #fff;
            border: 1px solid #DBDBDB;
            color: $darkgrey;
            cursor: pointer;
            position: relative;

            &.active,
            &:hover {
                background: #E7FFF0;
                color: #22643C !important;
                border: 1px solid #B3EFC9;

            }

            &.all-prices {
                padding: calcRem(5px) calcRem(20px) calcRem(5px) calcRem(20px) !important;
            }


            &.active {
                padding: calcRem(5px) calcRem(30px) calcRem(5px) calcRem(20px);

                .fa {
                    display: block;
                }
            }

            .fa {
                position: absolute;
                right: calcRem(10px);
                display: none;
                float: left;
                line-height: 1;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}