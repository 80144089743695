@import "./../../styles/variables";
@import "./../../styles/functions";

.rotating-banner {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__img {}

  .carousel {
    position: static;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;

    .carousel-inner>.carousel-item {
      opacity: 0;
      top: 0;
      width: 100%;
      display: block;
      z-index: 0;
      border-bottom-right-radius: calcRem(100px);
    }

    .carousel-inner>.carousel-item.active {
      opacity: 1;
      z-index: 3;
    }

    .carousel-inner>.carousel-item {
      :first-of-type {
        position: relative;
      }
    }

    .carousel-inner>.carousel-item {
      transition: all 0.5s;
    }

    .carousel-control-prev,
    .carousel-control-next {
      transition: opacity 0.6s ease-in-out;
      opacity: 1;
      z-index: 6;
      position: absolute;
    }
  }


  &__img img {
    border-bottom-right-radius: calcRem(100px);
  }

  &__text {
    color: $white;
    z-index: 9999;
    position: relative;
  }

  @include media-breakpoint-down(md) {
    padding: 0 0px;
  }
}