@import "variables";
@import "global";
@import "./../styles/functions";

.products {
  padding: calcRem(64px);
  margin-bottom: calcRem(60px);

  .supertitle {
    margin-bottom: calcRem(15px);

    h4 {
      text-align: center;
      font-size: calcRem(18px);
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-down(md) {
    padding: calcRem(0px);
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: calcRem(100px);
  }

  &__header {
    padding-bottom: calcRem(70px);

    @include media-breakpoint-down(md) {
      padding-top: calcRem(30px);
      padding-bottom: (10px);
    }

    &__title {
      text-align: center;
      font-weight: $bold;
      font-size: calcRem(35px);
    }

    &__search {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__row {
    @include media-breakpoint-down(sm) {}
  }
}