
@import "variables";
@import "global";
@import "./../styles/functions";

.account-summary{

    padding-bottom: 60px;
    .card{
        padding:40px;
        border: 1px solid transparent;
    }
   
}