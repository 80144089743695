@import "variables";
@import "global";
@import "./../styles/functions";


.center-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@include media-breakpoint-down(sm) {
  .product-pricing{
    .font-size-title{
      font-size: 20px;
    }

    > div{
      text-align: left;
    }
    .text-small{
      display: block;
      margin-left: -8px;
    }
  }
}


.product {
  &__image {
    padding-top:calcRem(20px);
    width: 100%;
    height: auto;
    border-radius: 0;
    margin-bottom: calcRem(10px);
    object-fit: cover;
    &:hover{
      cursor: pointer;
    }
    &__active{
      border: thin solid $orange;
      padding: calcRem(5px);
    }
  }
  &__checkmark {
    position: absolute;
    transform: translate(calcRem(105px), calcRem(15px));
    width: calcRem(30px);
    height: calcRem(30px);
    border-radius: 0;
    border: none;
    padding: 0;
    margin-bottom: 0;
    object-fit: contain;
  }
}

.product-images{  
  @include media-breakpoint-down(md) {
      .col-sm-4{
        width:33%;
      }
  }
  @include media-breakpoint-down(sm) {
      .col-sm-4{
        width:50%;
      }
  }
}
.order-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calcRem(20px);

}
