@import "./../../styles/variables";
@import "./../../styles/functions";
@import "./../../styles/ProductCard";
.stock-date{
  color: #777D8D;
}
.product-card-y {
  .expected-stock--date{
    font-size: 0.8rem;
    font-weight: bold;
    color:$bordergrey;
  }
  #z-indexer {
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(md) {
      z-index: 2;
    }
  }

  &__sub-header {
    padding: 0 calcRem(20px);
  }

  &__caret {
    font-size: calcRem(30px);

    polyline {
      stroke: $arrowGrey;
    }
  }

  &__accordion {
    background-color: $midgrey;
    border-radius: calcRem(20px);
    padding: calcRem(20px) calcRem(30px);

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__title {
        font-size: $fontSizeMedium;
        font-family: $fontRoboto;
        font-weight: $bold;
        color: $darkgrey;
        text-transform:initial;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__body {
      padding-top: calcRem(20px);
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: calcRem(20px);

    &__img {
      display: flex;
      justify-content: center;
      align-items: center;

      &__name {
        padding-left: calcRem(20px);

        &__top {
          font-size: calcRem(17px);
          font-weight: $bold;
        }

        &__bottom {
          font-size: calcRem(14px);
        }
      }
    }

    &__img img {
      width: calcRem(90px);
      height: calcRem(90px);
      border-radius: calcRem(10px);

    }
  }

  &__price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__right {
      width: 100%;
      min-height: calcRem(50px);
      padding: calcRem(15px);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-top: solid 1px $lightgrey;
      border-bottom: solid 1px $lightgrey;

      &__pack {
        font-size: calcRem(14px);
      }
    }
  }

  &__sqm {
    display: flex;
    justify-content: space-between;

    &__desc {

      padding-bottom: calcRem(10px);

      &__top {
        font-weight: $bold;
      }

      &__bottom {
        font-size: calcRem(11px)
      }
    }
  }
}

.plus-minus-controls {
  display: inline-block;
  width: calcRem(20px);
  text-align: center;
  font-size: $fontSizeSmall;

  &:hover {
    cursor: pointer;
  }
}


.hide-desktop{
  display: none;
}


@include media-breakpoint-down(sm) {
  .hide-desktop{
    display: block;
  }
  .product-card-ex__summary__col__subtotal{
    width:50%;
    padding-top:calcRem(10px);
  }
  .product-card__summary__status{
    width: 50%;
    position: static;
    order:7;
    padding-top:calcRem(10px);
  }
  .product-card-ex__summary__width,
  .product-card-ex__summary__length,
  .product-card-ex__summary__status{
    width:50%;
  }
  .product-card-ex__summary__delivery,
  .product-card-ex__summary__reference{
    width: 100%;
  }
  .calculation{
    flex-wrap: wrap;
  }
  .calculation__left{
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .calculation__right{
    width:100% !important;
  }
  .calculation__mid{
    display: none !important;
  }
  .table-contents {
    .inner {
      padding: 0 5px;
    }
  }
  .mobile-cut-summary{
    flex-wrap: wrap;
    > div{
      width: 100%;
    }
  }

  .table--row {
    .table--row__title__batch {
      width: 50%;
      order: 1;
    }

    .table--row__title__delivery {
      width: 50%;
      order: 2;
    }

    .table--row__title__width {
      order: 4;
      width: 50%;
    }

    .table--row__title__length {
      width: 50%;
      order: 5;
    }

    .table--row__title__price {
      width: 50%;
      order: 5;
    }

    .table--row__title__stock {
      width: 50%;
      order: 6;
    }
  }
}