@import "./../../styles/variables";
@import "./../../styles/functions";

.myaccount-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calcRem(40px);
  width: 100%;
}


