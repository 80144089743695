@import "./../../styles/variables";
@import "./../../styles/functions";

.preicelist-search {
  .search-bar {
    width: 100%;
    border-radius: 0;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calcRem(0px);
    box-shadow: none !important;


    .search-bar__input{
      text-transform: capitalize;
    }
    &__left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__right {

      &:hover {
        cursor: pointer;
      }
    }

    &__input {
      flex: 1;
      border: none;
      color: $darkgrey;
      padding: calcRem(15px) calcRem(15px);
      outline: none;
      background: #F9F9F9;
    }
  }
}