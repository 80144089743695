@import "./../../styles/variables";
@import "./../../styles/functions";

.multi-choice {

  &:hover {
    cursor: pointer;
  }

  max-width: 100%;
  width:100%;
  min-height: calcRem(74px);
  background-color: $white;
  border-radius: calcRem(20px);
  border: 1px solid $lightgrey;
  padding: calcRem(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calcRem(13px);
  &__left{
    width:33%; 
  }
  &__center{
    width:33%;
    border-left: 1px solid $lightgrey;
    border-right: 1px solid $lightgrey;
    padding-left:15px;
  }
  &__right{
    padding-left:15px;
    width:33%;
  }
  &__left {
    display: flex;
  }
  .green-circle{
    margin-right:0;
    margin-top:0;
    padding:0;
  }
}
