@import "./../../styles/variables";
@import "./../../styles/functions";

.page-header {
  width: 100%;
  height: calcRem(400px);
  @include media-breakpoint-down(md) {
    height: calcRem(240px);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__img img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: calcRem(400px);
    @include media-breakpoint-down(md) {
      height: calcRem(240px);
    }
    object-fit: cover;
    border-bottom-right-radius: calcRem(100px);
  }

  &__text {
    color: $white;
    z-index: 9999;
    position: relative;
  }
  @include media-breakpoint-down(md){
    padding:0 30px;
  }
}
