@import "./../../styles/variables";
@import "./../../styles/functions";

.image--list{
    padding: calcRem(20px);  
    cursor: pointer; 
    .badge-clearance{
        background: $orange;
        position: absolute;
        right: 15px;
        top:50%;
        transform: translateY(-50%);
        border-radius: 25px;
        padding:5px 10px;
        font-weight: normal;
    }
    &__row{
        width:100%;
        padding:calcRem(5px) calcRem(15px);
        position: relative;
        &__selected{
            display: none !important;
        }
    }
    &__row__active{
        @include media-breakpoint-up(md) {
            border:1px solid $orange;            
        }
    }
    &__left__dot{
        margin-right: calcRem(20px);;
        @include media-breakpoint-down(md) {
            display: none;            
        }
    }
    &__selected{
        font-size: 1rem;
        display: block;
        padding-bottom: calcRem(15px);
    }
    &__row__active{
        @include media-breakpoint-down(md) {
            border: 1px solid $orange;   
            .image--list__left__dot{
                display: none;
            }
        }
    }
    &__row__selected{
        display: none;
        @include media-breakpoint-down(md) {
           position: absolute;
           top:50%;
           transform: translateY(-50%);
           right:calcRem(15px);
           display: block;        
        }

        @include media-breakpoint-down(sm){
            display: none;
        }
    }
    &__scroll{
        max-height: 300px;
        overflow-y: auto;
        padding-right: 20px;
    }
    &__name{
        text-transform: capitalize;
    }
    &__product{
      
    }
    &__image{
        img{
            width:calcRem(60px) !important;
            height:calcRem(60px) !important;
            border-radius:50%;
        }
    }
}