@import './../../styles/variables';
@import './../../styles/functions';

.lw-button {
  color: $white;
  border-radius: calcRem(32.5px);
  border: none;

  &:hover {
    box-shadow: calcRem(1px) calcRem(1px) calcRem(5px) rgba(0, 0, 0, 0.4);
  }

  &:active {
    box-shadow: inset calcRem(1px) calcRem(1px) calcRem(5px) rgba(0, 0, 0, 0.4);
  }
}

.lw-button.lw-button-square{
  border-radius: 0 !important;
}
.lw-button.lw-button-chamfer{
  border-radius: calcRem(10px) !important;
}
.lw-button-orange {
  background-color: $orange;
}

.lw-button-grey {
  background-color: $darkgrey;
}
.lw-button-mid-grey{
  background-color: $midgreybutton;
  color:white;
}
.lw-button-light-grey {
  background-color: $lightergrey;
  color: $darkgrey;
}

.lw-button-link {
  color: $orange;
  background-color: transparent;
  min-width: auto;
  height: auto !important;
  padding: calcRem(5px) calcRem(5px) !important;
  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
}

.lw-button-sm {
  height: calcRem(36px);
  min-width: calcRem(110px);
  padding: calcRem(10px) calcRem(20px);
  font-size: $fontSizeExtraSmall;
}
.lw-button-md {
  height: calcRem(60px);
  min-width: calcRem(160px);
  padding: calcRem(10px) calcRem(20px);
  font-size: $fontSizeSmallMedium;
}

.lw-button-lg {
  height: calcRem(65px);
  min-width: calcRem(160px);
  padding: calcRem(10px) calcRem(20px);
  font-size: $fontSizeSmallMedium;
}

.lw-button-stretch {
  height: calcRem(65px);
  min-width: 100%;
  padding: calcRem(10px) calcRem(20px);
  font-size: $fontSizeSmall;
}

.lw-button-disabled {
  opacity: 0.6;
}
