@import "./../../styles/variables";
@import "./../../styles/functions";

.text-area-container {
  border: none;
  width: 100%;
  
  .form-label {
    font-size: $fontSizeSmall;
    padding-left: calcRem(5px);
  }

  textarea {
    width: 100%;   
    border: thin solid transparent;
    color: $darkgrey;
    border-radius: 0 !important;
    padding:calcRem(20px) !important;
    border-color: $lightgrey !important;
    border-radius: 0 !important;
    color: $darkgrey !important;
    min-height: calcRem(80px);
  }



  .error {
    border: 1px solid red !important;
  }

  .error-lbl {
    color: red !important;
  }

  .white {
    background-color: $white;
  }

  .grey {
    background-color: $midgrey;
  }
}
