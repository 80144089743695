@import "./../../styles/variables";
@import "./../../styles/functions";

.table-header {
  font-size: calcRem(13px);
}

.btn-yellow {
  border-radius: calcRem(25.5px) !important;
  background-color: $yellow !important;
  font-size: calcRem(13px) !important;
  border: none !important;
  outline: none !important;
}
.w-minus-img{
  width:calc(100% - 3rem);
}

.btn-green {
  border-radius: calcRem(25.5px) !important;
  background-color: $green !important;
  font-size: calcRem(13px) !important;
  border: none !important;
  outline: none !important;
}

.product-row__img {
  width: calcRem(75px);
  height: calcRem(45px);
  border-radius: calcRem(5px);
  object-fit: cover;
  margin-right: calcRem(15px);
}

.border-bottom-yay {
  border-bottom: thin solid #DEDEDE;
  padding-bottom: calcRem(10px);
  margin-bottom: calcRem(10px);
}

.text-grey-smaller {
  color: $darkgrey;
  font-size: calcRem(12px) !important;
}

.text-grey-small {
  color: $darkgrey;
  font-size: calcRem(14px) !important;
  font-weight: $bold;
}

.text-grey-smaller {
  color: $darkgrey;
  font-size: calcRem(12px) !important;
}
.order-summary {
  width: 100%;
  background-color: $white;
  border-radius: calcRem(20px);
  margin-bottom: calcRem(20px);
  .row{
    position: relative;
  }
  .hr-line {
    width: 100% !important;
    height: calcRem(1px);
    background-color: $lightgrey;
    margin-top: calcRem(30px);
    margin-bottom: calcRem(30px);
  }
  .pay-button{
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 3;
  }
  &__table {
    @include media-breakpoint-down(md){
      .px-5{
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
    }
    &__content{
      @include media-breakpoint-down(md){
        display: flex;
        justify-content: flex-start;
        > div{
          width:auto !important;
          padding-bottom: 15px 15px;
          text-align: left;
          padding:0 15px;
          .px-2{
            font-size: 14px;
            padding:0 !important;
            margin:0 !important;
          }
        }
      }
    }
  }
}
