@import "./../../styles/variables";
@import "./../../styles/functions";

.search-filter {
    align-items: center;
    padding-bottom: calcRem(20px);
    flex-grow: 1;
    @include media-breakpoint-down(xs){
      flex-wrap: wrap;
    }
 

    .search-filter__control{
      .lw-button{      
        border-radius: calcRem(20px);
        height: calcRem(80px);
      }
    }
 
    &.d-table{
      display: table;
      width:100%;
      vertical-align: top;
      > div{
        display:table-cell;
        vertical-align: top;
        @media(max-width:400px){
          display: block;
          width:100%;
        
        }
      }
    }
    .search-filter__bar {
      position: relative;
      padding-right: calcRem(15px);
      @media(max-width:400px){
        padding-right: 0;
      }
      .search-bar{
        box-shadow: none !important;
        background: $lightergrey;     
        max-width: 100%;
        height: calcRem(50px);
        padding: calcRem(40px) calcRem(30px) calcRem(40px)  calcRem(30px);
        input[type="text"]{
          background: none;
        }
      }
    }
    .search-filter__control{
      width: calcRem(160px);
      button{
        width:100% !important;
      }
    }

    &.search-page{
      @include media-breakpoint-down(md) {
        flex-wrap: nowrap;
      }
      @include media-breakpoint-down(sm){
        flex-wrap: wrap;
      }
   
    }
    @include media-breakpoint-down(md){
      .col-lg-9{
        width:75% !important;
      }
      .col-lg-3{
        width:25% !important;
      }
    }
    @include media-breakpoint-down(sm){
      .row{
        flex-wrap: nowrap;
        justify-content: space-between;
      }
      .col-lg-9{
        width:70% !important;
      }
      .col-lg-3{
        width:auto !important;
      }
    }
    @include media-breakpoint-down(md){
      position: relative;
      .search-bar{
        height: auto;
        border-top-right-radius: 1.25rem;
        border-bottom-right-radius: 1.25rem;
        padding:0.5rem 1rem 0.5rem 1rem;
        input{
          background:none;
          max-width: 100% !important;
        }
        @include media-breakpoint-down(sm){
          position: relative;
          &__right{
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    &__control {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      @include media-breakpoint-down(md) {
        //padding-top:15px;
      }
      @include media-breakpoint-down(md){
        .lw-button{
          min-width: 4rem !important;
       
        }
        
      }
    }

    @media(max-width:400px){
      .lw-button{
        width:auto !important;
        padding:calcRem(15px) calcRem(30px) !important;
        height:auto !important;
        margin-top:15px;
      }
    }  
  }
