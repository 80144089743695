@import "./../../styles/variables";
@import "./../../styles/functions"; 

.context-menu-container {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: rgba(0,0,0,0.2);
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 99999;
}



.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  .icon{
    margin-right: 10px;
    svg{
      width: 14px;
    }
  }
}

.context-menu li {
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #eee;
}