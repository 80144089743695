@import './../../styles/variables';
@import './../../styles/functions';

.tooltipviewer-outer{
  padding-left: 5px;
  display: inline-block;
  position: relative;

  .position-relative{
    font-size: 0.9rem;
  }
  svg{
    width:calcRem(16px);
  }
  .tooltip-icon{
    display: inline-block;
    cursor: pointer;
    margin-left: 4px;
    fill: #767C8C;
  }
  .tooltipviewer-text{

      
    &:after, &:before {
      bottom: 100%;
      left: 90%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #FFF;
      border-width: calcRem(16px);
      margin-left: calcRem(-16px);
    }
    &:before {
      border-color: rgba(198, 198, 198, 0);
      border-bottom-color: #C6C6C6;
      border-width: calcRem(17px);
      margin-left: calcRem(-17px);
    }

    font-weight: normal;
    display: block;
    clear: both;
    position: absolute;
    top:calcRem(35px);
    right: calcRem(-26px);
    background: #FFF;
    padding:calcRem(15px);
    max-width: calcRem(340px);
    width: calcRem(340px);
    border: 1px solid #C6C6C6;
    @include box-shadow;
    z-index: 99;

    @include media-breakpoint-down(xs) {
      left:auto;
      right: calcRem(-16px) !important;
      max-width: calcRem(270px);
      width: calcRem(270px);
      &:after, &:before {        
          right: auto;
          transform: translateX(calcRem(-22px));      
          left:100%;  
      }
    }
  }
  .tip-title{
    display: block;
    font-size: 1.0rem;
    color: #777D8D;
    font-weight: 500;
    position: relative;
    img{
      width:calcRem(18px);
      position: absolute;
      right:0;
      top:0;
      cursor: pointer;
    }
  }
  .tiptext{
    font-size: 0.8rem;
    color: #777D8D;
  }

  &.tooltip-side-right{
    .tooltipviewer-text{
      right:auto;
      left:calcRem(-18px);
      &:after, &:before {
        left: 10%;
      }
      
      @include media-breakpoint-down(lg) {
        left:auto;
        right: calcRem(-26px);
        &:after, &:before {
          left: 90%;
        }
      }
      @include media-breakpoint-down(xs) {
        left:auto;
        right: 0;
        &:after, &:before {        
          right: auto;
          transform: translateX(calcRem(-22px));      
          left:100%;  
      }
      }
    }
  }
}
