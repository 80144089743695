@import "./../../styles/variables";
@import "./../../styles/functions";

.pricelist-table-scroll {
    max-height: 600px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $orange;
        cursor: pointer;
    }
}

.pricelist-table {
    margin-top: 0;
    width: 100%;

    table {

        th,
        td {
            text-align: center;
        }
      table-layout: fixed;
    }
    th + th{
        text-align: center;
    }
    th:first-child{
        max-width: 30%;
        width:30%;
    }
    thead {
        top: 0;
        position: sticky;
        -webkit-box-shadow: 0px 2px 3px 0px rgba(138,138,138,0.1);
        -moz-box-shadow: 0px 2px 3px 0px rgba(138,138,138,0.1);
        box-shadow: 0px 2px 3px 0px rgba(138,138,138,0.1);
    }

    th {
        background: #FFF;
        border-bottom: 1px solid #EFEFEF;
    }

    .qual-title + span{
        display: block;
        padding-left: 15px;
        padding-top:5px;
    }
    .row-indent{
        td:first-child{
            padding-left: 45px !important;
        }
    }
    td {
        &:first-child {
            text-align: left !important;

            span {
                text-align: left !important;
            }
        }

        span {
            display: block;
            text-align: center;
            white-space: nowrap;

            span {
                display: inline;
                color: $orange;
            }

            &.col-title {
                padding-bottom: 5px;
            }

            &.highlight {
                color: $orange;
            }
        }
    }

    th {
        padding: 15px 5px 15px 5px;
        font-weight: normal;
        font-size: 0.9rem;

        @include media-breakpoint-down (lg) {
            padding: 10px 5px 10px 5px;
            font-size: 0.8rem;
         }

        &:first-child {
            border-right: 1px solid #F9F9F9;
            text-align: left !important;

            span {
                text-align: left;
            }
        }

        &:last-child {
            border-right: 0;
            ;
        }


        &.highlight {
            color: $orange;
        }
    }


    tbody {
        font-size: 0.9rem;
        padding-top: 15px;

        tr:nth-child(odd) {
            background: #F9F9F9;
        }

        td {
            color: #696969;
            padding: 5px 10px !important;
            @include media-breakpoint-down (lg) {
                padding: 5px 5px !important;
                font-size: 0.8rem;
             }
    

            tr {
                background: none !important;
            }

            th {
                background: none;
                padding: 5px;
                border: 0;

            }
        }
    }
}