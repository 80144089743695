@import "variables";
@import "global";
@import "./../styles/functions";


.my-statements {
    padding-top: calcRem(50px);

    .account-balance {
        font-size: 1.3rem;
    }

    .search-row .lw-pagination {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        column-gap: 30px;
    }

    .tabs-right{
        .dropdown{
            width:300px;
        }
        button{
         background-color: #F7F7F7;;
        }
        button,
        .dropdown{
            border: 0;
            background-color: #F7F7F7 !important;
            border-radius: calcRem(5px);
        }
    }

    .invoices-selected-div {
        border-right: 1px solid $orange;
        padding-right: calcRem(15px);

        &+div {
            padding-left: calcRem(15px);
        }
    }

    .tabs-left ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style: none;
        margin-left: 0;
        padding-left: 0;

        li {
            a {
                padding: calcRem(15px) calcRem(25px);
                text-decoration: none;
                display: block;
                
                &:hover,
                &:focus,
                &.selected {
                    background: $orange;
                    color: #FFF;
                    border-radius: calcRem(5px);
                }
            }

            margin-right: calcRem(15px);
        }
    }

}

.tab-row {
    padding-top: calcRem(25px);
}

.payments-table {
    margin-top: calcRem(10px);

    table {
        width: 100%;
    }

    .checkbox {
        svg {
            width: 15px;
        }
    }

    .cursor {
        cursor: pointer;
    }

    .fill-square {
        svg {
            fill: $orange;
        }
    }

    .checkbox-col {
        width: 30px;
    }

    thead {
        background-color: $paygrey;
        border-radius: calcRem(8px);
        padding: calcRem(15px);
        text-align: left;

        th {
            font-weight: normal;
            padding: calcRem(18px) 0;

            &:first-child {
                border-top-left-radius: calcRem(8px);
                border-bottom-left-radius: calcRem(8px);
                padding-left: calcRem(15px);
                padding-right: calcRem(15px);

            }

            &:last-child {
                border-top-right-radius: calcRem(8px);
                border-bottom-right-radius: calcRem(8px);
            }
        }

    }

    tfoot {
        background-color: $deliverygrey;
        border-radius: calcRem(8px);

        td {
            padding: calcRem(15px);
            & + td{
                padding-left: 0;
            }

            &:first-child {
                border-top-left-radius: calcRem(8px);
                border-bottom-left-radius: calcRem(8px);
            }

            &:last-child {
                border-top-right-radius: calcRem(8px);
                border-bottom-right-radius: calcRem(8px);
            }
        }
    }

    tbody {
        .ellipsis {
            svg {
                width: 5px;
                cursor: pointer;
            }
        }

        td {
            padding: calcRem(15px) calcRem(3px);
            color:$textgrey;
            font-size: 0.9rem;

            &:first-child {
                padding-left: calcRem(15px);
                padding-right: calcRem(15px);
            }
        }
    }
}