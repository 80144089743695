@import "./../../styles/variables";
@import "./../../styles/functions";

.search-bar {
  max-width: calcRem(985.008px);
  width: 100%;
  height: calcRem(90px);
  background-color: $white;
  border-radius: calcRem(20px);
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calcRem(40px);
  -webkit-box-shadow: 2px 12px 8px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 2px 12px 8px 5px rgba(0, 0, 0, 0.03);


  &__left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      color: $darkgrey;
      font-size: calcRem(24px);
      margin-right: calcRem(10px);
    }
  }

  &__right {
    padding: calcRem(5px);

    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    flex: 1;
    border: none;
    color: $darkgrey;
    height: calcRem(48px);
    outline: none;
  }
}
