@import "./../../styles/variables";
@import "./../../styles/functions"; 

.payment-modal{
    padding:calcRem(30px);
}
.payment-modal__row{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    font-size: 0.9rem;
    color: $paygreyText;
    img{
        max-width: 100%;
    }
    .payment-modal__icon{
        padding:calcRem(10px);
        margin-right: calcRem(10px);
        background: $paygrey;;
        border-radius: calcRem(5px);
        svg{
            width:30px;
            fill:$orange;
        }
    }
    > div{
        width:50%;
    }
    h3{
        font-size: 1.3rem;
        color:$darkgrey;
    }
    .payment-modal__title{
        padding-bottom: calcRem(10px);
    }
    .checkbox{
        cursor: pointer;
        svg{
            width:16px;
        }
    }

    .payment-modal__body-text .text-input-container input{
        border-radius: 3px !important;
    }

    .payment-modal__col--right{
        background-color: $paygrey;
        border-radius: 8px;
        padding:calcRem(15px);
        .payment-modal-right__body{
            max-height: 300px;
            font-size: 0.9rem;         
            overflow-y: auto;
        }
        .payment-modal-right__body-row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:3px 0;
            color:$darkgrey;
        }
        .checkbox{
            svg{
                fill:$paygreyText;
            }
        }
        .fill-square{
            svg{
                fill:$orange;
            }

        }
        .payment-modal-first-col{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .checkbox-col{
                width:30px;
            }
        }
        .payment-modal-right__footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.9rem;
            padding-top:calcRem(10px);
        }
    }

    .payment-modal__col--left{
        padding-right: 15px;
        align-items: stretch;
        flex-grow: 1;
        .payment-modal__body{
            flex-grow: 1;
        }
        >div{
            display: flex;
            align-items: stretch;
            flex-direction: column;
            height: 100%;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    .payment-section__row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:calcRem(5px) 0;
        &__value{
            font-size: 1.0rem;
            font-weight: 500;
        }
    }
    .payment-button-outer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .payment-section__row-value{
        color:$darkgrey
    }
}