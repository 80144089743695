@import "./../../styles/variables";
@import "./../../styles/functions";
.popup-basket-outer{
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    z-index: 999999;
    .popup-basket{
        background: #FFF;
        max-width: 100%;
        width:600px;
        position: absolute;
        right: 0;
        top:0;
        padding-top:calcRem(15px);
        .btn-back{
            position: absolute;
            top:calcRem(30px);
            left:calcRem(30px);
            width:30px;
            height: 30px;
            padding:0;
            display: block;
            img{
                width: calcRem(30px) !important;
                height: calcRem(30px) !important;
            }
        }
    }


    .orders-footer{
        border-top:1px solid $lightgrey;
        padding:calcRem(30px);
        .lw-button{
            display: block;
            text-decoration: none;
            border-radius: 0;
            text-align: center;
            padding: calcRem(15px) calcRem(20px);
            &:hover{
                color:$white;
            }
        }
    }
    .orders-footer__text{
        font-size: 0.9rem;
    }
    .orders__search{
        flex-wrap: wrap;
        max-height: 400px;
        overflow: auto;
        @media(max-width:400px){
            max-height:25vh;
        }
    }
}