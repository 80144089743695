@import "variables";
@import "global";
@import "./../styles/functions";

.about {
  padding-top: calcRem(20px);
  &__hero {
    display: flex;
    justify-content: center;
    align-items: center;
    &__left {
      display: flex;
      z-index: 999;
      @include media-breakpoint-down(lg) {
        padding-top: calcRem(50px);
      }
    h1 {
      font-size: calcRem(55px) ;

    }
      &__line {
        width: calcRem(5px);
        max-height: calcRem(145px);
        background-color: $orange;
        margin-right: calcRem(30px);
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
    &__image {
      width: 100%;
      max-height: calcRem(380px);
      object-fit: cover;
      background-position: center;
      @include media-breakpoint-down(sm) {

      }
    }
  }
  &__desc {
    padding-top: calcRem(40px);
  }
  &__img__section {
    padding-top: calcRem(40px);
    &__image {
      width: 100%;
      height: calcRem(400px);
      object-fit: cover;
      background-position: center;
    }
  }

}


