@import "./../../styles/variables";
@import "./../../styles/functions";
.dashbanner{
  position:relative;
  .dashbanner--controls{
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right:calcRem(30px);
    display:block;
    text-align: right;
    >a{
      text-align: right;
      width:10px;
      margin-right: 1px;
    }
    
  }
  .previous, 
  .next{
    text-align:right;
    color:$lightgrey;
    transition:all 0.5s;
    font-size: calcRem(20px);
    color:#FFF;
    line-height: 0.5;
    display:inline-block;
    height: calcRem(10px);
    margin:0;
    padding:0;
    &:hover{
      color:#FFF;
      opacity: 1;
      transition:all 0.5s;
    }
  }
  .next{
    transform: rotate(180deg);
    transform-origin: center;
    margin-top:calcRem(8px);
  }
 
  .dashbanner--controls--numbers{
    display:flex;
    flex-direction: column;

    > a{
      display: flex;
      justify-content: flex-end;
      margin-top:  calcRem(5px);
      margin-bottom: calcRem(5px);
      color:#FFF;
      font-size: calcRem(10px);
      align-items: center;
      transition:all 0.5s;
      position:relative;      
      .number{
        color:$lightgrey;
        transition:all 0.5s;
        position: absolute;
        right:100%;
        &:hover,
        &.is-active{
          color:#FFF;
          opacity: 1;
          transition:all 0.5s;
        }
      }
     
    }
  }
  .circle{
    width:18px;
    height:18px;
    background: white;
    border-radius: 50%;
    display:block;
    margin-left:calcRem(5px);
    &.bg-orange{
      background: $orange;
    }
  }
}