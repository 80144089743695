@import './../../styles/variables';
@import './../../styles/functions';

.tooltip-outer{
  padding-left: 5px;
  display: inline-block;

  .position-relative{
    font-size: 0.9rem;
  }
  svg{
    width:14px;
  }
  .tooltip-icon{
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    margin-left: 4px;
  }
  .tooltip-text{
    font-weight: normal;
    display: block;
    clear: both;
  }
}
