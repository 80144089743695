@import "./../../styles/variables";
@import "./../../styles/functions";

.checkout-item {
  &.popup-checkout-item {
    padding-left: 40px;
    position: relative;
    width:100%;
    box-shadow: none;
    margin-bottom: 0;
    .checkout-item-left{
      padding-left: 40px;
      position: relative;
      width:25%;
      img{
        border-radius: 50%;
        width: 70px;
        height:70px;
        @media(max-width:400px){
          width:40px;
          height: 40px;;
        }
      }
      .remove-action{
        position: absolute;
        top:50%;
        left:0;
        transform: translateY(-50%);
        text-decoration: none;
        color:$white;
        background: $arrowGrey;
        border-radius: 50%;
        width:20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
      }
    }
  }
  .popup-product {
    width:100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* order summary */
    &__row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-top: 1px solid $lightgrey;
      padding-bottom: calcRem(30px);
      padding-top: calcRem(30px);

      &:first-child {
        padding-top: 0;
        border-top: 0;
      }
    }

 

    &__col__title {
      width: 45%;

    }

    &__col__colour {}

    .summary-content-text {
      font-weight: 500;
      font-size: 0.9rem;
    }

    &__reference {
      width: 27.5%;
    }

    &__col__subtotal {
      width: 27.5%;
      margin-right: 0;
    }

    &__delivery {
      width: 45%;

      .summary-content-text {
        padding-left: 5px;
        font-size: 0.9rem;
      }
    }

    &__width {
      width: 18.3%;
    }

    &__length {
      width: 18.3%;
    }

    &__area {
      width: 18.3%;
    }

    &__col__title__name {
      font-weight: 500;
      text-transform: capitalize;
    }

    &__col__colour {
      display: block;
      font-size: 1rem;
    }

    &__reference,
    &__col__title,
    &__col__colour,
    &__col__subtotal,
    &__col__reference {
      padding-bottom: calcRem(5px);

      .text-grey {
        display: block;
      }
    }

 
    @include media-breakpoint-down(md) {
      &__col__title {
        width: 100%;
        order: 1;
      }

      &__reference {
        width: 50%;
        order: 2;
      }

      &__delivery {
        width: 50%;
        order: 3;
      }

      &__col__subtotal,
      &__area,
      &__length,
      &__width {
        width: 25%;
        padding-top: calcRem(15px);
      }

      &__width {
        order: 4;
      }

      &__length {
        order: 5;
      }

      &__area {
        order: 6;
      }

      &__col__subtotal {
        text-align: right;
        order: 7;
      }
    }
  }
}

.popup-product-packs{
  .popup-product__col__title{
    width:50%;
  }
  .popup-product__reference{
    width:33%;
  }
  .popup-product__length{
    width:33%;
  }
}
