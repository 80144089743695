@import "./../../styles/variables";
@import "./../../styles/functions";

.btn-success {
  color: $darkgrey !important;
  background-color: $white !important;
  border: none !important;
  height: calcRem(55px) !important;
  border-radius: calcRem(27.5px) !important;
  padding: calcRem(20px) !important;
}
.dropdown-menu.show {
  width: 100%;
}
.dropdown {
  border: thin solid $bordergrey;
  border-radius: calcRem(30px);
}

button.dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 1;
}
.colour-orange{
  background: $lightOrange;
  color: $orange;
}
.dropdown{

  .badge {
    font-size: 0.7rem;
    font-weight: normal;
    border-radius: 15px;
    padding-left: calcRem(15px);
    padding-right: calcRem(15px);
    padding-top: calcRem(8px);
    padding-bottom: calcRem(8px);
    background: $lightred;
    color:#FFF !important;
    margin-left: calcRem(5px);
    &.colour-orange{
      background: $presold;
      color: $orange !important
    }
  }
}