@import "./../../styles/variables";
@import "./../../styles/functions";

.account-card--header{
     h4{
            display: flex;
            width:100%;
            justify-content: space-between;
            align-items:baseline;
    }
    padding-bottom: 15px;

    .account-card-balance{
        font-weight: normal;
        font-size: 1rem;
    }
    
}
.account-card--row{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .table-row{
        font-size: 0.9rem;
        color:$textgrey;
        padding-bottom: 5px;
    }
    .table-header{
        font-size: 0.9rem;
        padding-bottom: 10px;       
        color: $orange;
    }
    .table-tot{
        font-weight: bold;
    }
}