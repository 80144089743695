@import "./../../styles/variables";
@import "./../../styles/functions"; 


.my-statements-bar{
  margin-top:calcRem(30px);
  padding:calcRem(5px);
  border-radius: calcRem(8px);
  padding-left: calcRem(15px);
  font-size: 0.9rem;
  background-color: #F7F7F7;
  .copy-icon{
    background: #FFF;
    padding:5px;;
    border-radius: 50%;
    width:30px;
    height: 30px;
    display: inline-block;
    margin-right: calcRem(15px);
    text-align: center;
    position: relative;
    svg{
      position: absolute;
      width: 15px;
      top:50%;
      left: 50%;
      transform:translateX(-50%) translateY(-50%);
    }
  }
}