@import "./../../styles/variables";
@import "./../../styles/functions";

.catalogueItem {
  min-height: calcRem(390px);
  max-width: calcRem(336px);
  background-color: $white;
  border-top-left-radius: calcRem(30px);
  border-top-right-radius: calcRem(30px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  @include media-breakpoint-down(md) {
    margin:auto;
  }

  .cost{
    font-weight: normal;
    small{
      font-size: 0.8rem;
    }
  }


  &:hover {
    cursor: pointer;
  }

  &.please-call-item{
    &:hover{
      cursor: initial;
    }
  }

  &__img {
    width: 100%;
  }
  &__img img {
    border: solid 1px $bordergrey;
    width: 100%;
    height: calcRem(336px);
    z-index: 99;
    border-radius: calcRem(30px);
    object-fit: contain;
  }

  &__img__placeholder {
    width: 100%;
    height: calcRem(336px);
    z-index: 99;
    border-radius: calcRem(30px);
    background-color: $darkgrey;
  }

  &__description {
    color: $darkgrey;
    font-weight: $bold;
    font-size: $fontSizeSmall;
    align-self: flex-end;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calcRem(16px);
    text-transform: capitalize;
    width: 100%;
    padding-left: 10px;
    padding-top:5px;
  }

  &__description p {
    margin-bottom: 0;
    &__price {
      margin-top: calcRem(48px);
    }
  }
}