@import "./../../styles/variables";
@import "./../../styles/functions";

.header-ticker{
    background-color: $orange;
    padding:calcRem(5px) 0;
}
.header-ticker-container{
    max-width: 600px;
    display: table;
    margin:auto;
    position: relative;
    padding-left: 80px;
    padding-right: 80px;
    overflow: hidden;
  
}
.header-ticker-content{
 
}
.header-ticker-text{
    text-align: center;
    color: #FFF;
    width:100%;
    cursor: pointer;
    display: none;
    &.active{
        display: block;
        color: #FFF !important;
    }
}
.header-ticker-left{
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
}
.header-ticker-right{
    position: absolute;
    right:0;
    top:50%;
    transform: translateY(-50%);
}
.header-ticker-left,
.header-ticker-right{
    img{
        width:10px;
        float: left;
    }
}