@import "variables";
@import "global";
@import "./../styles/functions";

.orders {
  padding-top: calcRem(40px) !important;
  padding-right: calcRem(40px) !important;
  padding-left: calcRem(100px) !important;
  background-color: $midgrey;

  @include media-breakpoint-down(lg) {
    padding-top: calcRem(30px) !important;
    padding-right: calcRem(50px) !important;
    padding-left: calcRem(50px) !important;
  }

  @include media-breakpoint-down(sm) {
    padding-top: calcRem(30px) !important;
    padding-right: calcRem(25px) !important;
    padding-left: calcRem(25px) !important;
  }

  &__title {
    padding-bottom: calcRem(30px) !important;
  }

  &__title h3 {
    font-size: calcRem(36px);
    font-weight: $bold;
  }

  &__search {
    display: flex;
    justify-content: space-between;
    margin-bottom: calcRem(30px);

    &__left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__text {
        margin-right: calcRem(10px);
      }
    }
  }
}

.collection {
  padding-top: calcRem(30px);

  &__branch {
    padding-top: calcRem(30px);

    text-transform: capitalize;
    p {
      font-size: calcRem(14px);
    }

    &__info {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width:1200px) {
        flex-wrap: wrap;

        >div {
          width: 100%;

          &:first-child {
            padding-bottom: 10px;
          }
        }
      }
    }

    &__other {
      text-decoration: none;
      .lower{
        text-transform: lowercase;
      }
    }
  }

}


.payment {
  h4 {
    color: $darkgrey;
    font-size: 1.3rem;
  }

  padding: calcRem(50px) !important;

  @include media-breakpoint-down(md) {
    padding: calcRem(30px) !important;
    margin-bottom: calcRem(80px);
  }

  &__details {
    padding: calcRem(30px);
    background-color: $lightOrange;

    svg {
      position: relative;
      top: calcRem(-2px);
    }

    a {
      color: $orange;
    }

    @include media-breakpoint-down(lg) {
      margin-top: calcRem(20px);
    }
  }

  &__info {
    padding-top: calcRem(30px);
  }

  &__delivery {
    padding-top: calcRem(30px);
  }

  &__billing {
    padding-top: calcRem(30px);

    &__address {
      text-transform: capitalize;

      &__title {
        font-weight: $bold;

        p {
          font-weight: normal;
          padding-bottom: calcRem(20px);
        }
      }

      &__link {
        display: flex;
        width: 100%;
        margin-bottom: calcRem(20px);

        .dropdown {
          border-color: $lightgrey !important;
          border-radius: 0 !important;
          color: $lightgrey !important;

          .dropdown-toggle {
            border-radius: 0 !important;
            background: $lightergrey !important;
            border-color: $bordergrey;
            color: $bordergrey;
          }
        }

        div {
          flex: 1;
        }

        img {
          padding-left: calcRem(10px);
        }
      }
    }
  }

  &__date {
    padding-top: calcRem(30px);

    &__info {
      display: flex;
      justify-content: space-between;

      &__details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;

        &__text {
          padding-right: 0;
          width: 100%;

          .dropdown {
            border-color: $lightgrey !important;
            border-radius: 0 !important;
            color: $lightgrey !important;

            .dropdown-toggle {
              border-radius: 0 !important;
              background: $lightergrey !important;
              border-color: $bordergrey;
              color: $bordergrey;
            }
          }
        }
      }
    }
  }

  .card-details__input {
    width: 100%;
    height: calcRem(60px);
    color: $darkgrey;
    outline: none;
    border-radius: calcRem(10px);
    border: solid 1px $bordergrey;
    padding-left: calcRem(10px);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(md) {
      padding-bottom: calcRem(80px);
    }

    &__disclaimer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: calcRem(40px);

      &__icon {
        margin-right: calcRem(10px);
      }

      &__text p {
        font-size: calcRem(15px);
        margin-bottom: 0;
        color: $lightgrey;
      }
    }
  }
}

.cart-btn {
  .lw-button {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    border-radius: 0;
  }
}

.continue-shopping {
  width: auto !important;
  margin: auto !important;
  display: table !important;
  font-weight: bold !important;
}

.total-text {
  font-size: 1.3rem;
}

.cart-alert {
  background: #FFFAEC !important;
  border: 1px solid #FFF5D8 !important;
  border-radius: calcRem(15px) !important;
  color: #333 !important;
  padding: calcRem(15px) calcRem(20px) !important;

  &.alert-small {
    margin: 0 !important;
  }

  .exclamation {
    height: calcRem(20px);
    position: relative;
    top: calcRem(-1px);
  }
}

.square-alert {
  background: #FFFAEC !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #333 !important;
  padding: calcRem(10px) calcRem(10px) !important;

  &.alert-small {
    margin: 0 !important;
  }

  .exclamation {
    height: calcRem(20px);
    position: relative;
    top: calcRem(-1px);
  }
}
.customer__reference__input{
  .form-control{
    border-radius: 0; 
    border: 1px solid #d3d3d3!important;
  }
}