@import "./../../styles/variables";
@import "./../../styles/functions";

.bg-white {
  background-color: $white;
}

.navbar-collapse{

  .dropdown-toggle{
    border:0 !important;
    font-size: 0.9rem;
    margin-left: calcRem(15px);


      
    @include media-breakpoint-down(lg) {
      display:  none !important;
    }

    &:hover{
      .account-no{
        color:$textgrey !important;
      }
    }
  }
  .account-no{
    display: inline-block;
    padding-right: calcRem(5px);
    color:$textgrey !important;
    & + span{
      display: inline-block;

    }
  }
  .dropdown-menu{
    a{
      color:$textgrey;
      &:active{
        background: $lightergrey !important;
      }
    }
  }
  .text-red{
    color: $orange !important;
  }
}
.nav-item.dropdown{
  border:0 !important;
}

.activeKey {
  color: $orange !important;
}
@include media-breakpoint-up(lg) {
  .desktop-hide{
    display: none !important;
  }
}
@include media-breakpoint-down(lg) {
  .mobile-hide{
    display: none !important;
  }
  .nav-link{
    display: block;
    width: 100%;
    border-bottom: 1px solid #EFEFEF;
    &:last-child{
      border-bottom: 0;
    }
    img{
      max-width: 25px;;
    }
  }
}
.cart-indicator {
  position: absolute;
  background-color: $orange;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fontSizeExtraExtraSmall;
  width: calcRem(20px);
  transform: translate(calcRem(17px), calcRem(-5px));
  height: calcRem(20px);
  text-align: center;
  border-radius: 50%;
}

.navbarFlex {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap;
}

.nav-container {
  padding-right: calcRem(80px) !important;
  padding-left: calcRem(80px) !important;
  @include media-breakpoint-down(lg) {
    padding-right: calcRem(25px) !important;
    padding-left: calcRem(25px) !important;
  }
  @include media-breakpoint-down(sm) {
    padding-right: calcRem(20px) !important;
    padding-left: calcRem(20px) !important;
  }
}

.nav-link {
  font-size: calcRem(16px);
  margin-right: calcRem(6px);
  @include media-breakpoint-down(md) {
    margin-right: calcRem(0px);
  }
}

.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link:hover {
  color: $orange !important;
}


.navbar-toggler{
  border:0 !important;
  label{
    display:flex;
    flex-direction:column;
    width:30px;
    cursor:pointer;
      span{
        background: #333;
        border-radius:16px;
        height:4px;
        margin: 3px 0;
        transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
      }
    }
    span:nth-of-type(1){
      transform-origin:bottom;
      transform:rotatez(45deg) translate(7px, 6px);
    }
    span:nth-of-type(2){   
      transform-origin:top;
      transform:rotatez(-45deg)
    }
    span:nth-of-type(3){   
      transform-origin:bottom;
      width:50%;
      transform: translate(8px, -11px) rotatez(45deg);   
    }
    
    &.collapsed{
      span:nth-of-type(1){
        width:100%;      
        transform: none;
      }
      span:nth-of-type(2){
        width:75%;
        transform: none;
      }
      span:nth-of-type(3){
        width:100%;  
        transform: none;   
      }
    }
}