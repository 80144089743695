@import "./../../styles/variables";
@import "./../../styles/functions";
@import "./../../styles/ProductCard";

.stock-date{
  color: #777D8D;
}
.product-card-units-pallets {
  .expected-stock--date{
    font-size: 0.8rem;
    font-weight: bold;
    color:$bordergrey;
  }
  #pallets {

    .no-pallets {
      margin-left: 15px;
      margin-right: 15px;
    }

    .stock_toggle__header__display {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .product-card__price__right {
        padding-right: calcRem(20px);
      }
    }

  }

  #z-indexer {
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(md) {
      z-index: 2;
    }
  }

  &__sub-header {
    padding: 0 calcRem(20px);
  }

  &__caret {
    font-size: calcRem(30px);

    polyline {
      stroke: $arrowGrey;
    }
  }

  .calculation--label {
    padding-right: calcRem(10px);
    padding-top: 0;
    width: calcRem(90px);
    display: inline-block;
    margin: 0;
    padding-bottom: 0;
  }

  .cut-area {
    font-size: 1.1rem;
  }

  .cut-subtotal {
    font-size: 1.3rem;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: calcRem(20px);

    &__img {
      display: flex;
      justify-content: center;
      align-items: center;

      &__name {
        padding-left: 0;
        text-transform: capitalize;

        &__top {
          font-size: calcRem(21px);
          font-weight: $bold;
          text-transform: capitalize;
        }

        &__bottom {
          font-size: calcRem(14px);
          text-transform: capitalize;
        }
      }
    }

    &__img img {
      width: calcRem(90px);
      height: calcRem(90px);
      border-radius: calcRem(10px);

    }
  }


  &__pricing {
    padding: calcRem(20px);
    padding-top: 0;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }

    .badge {
      background: $lightgreen;
      font-size: 0.8rem;
      font-weight: normal;
      border-radius: 15px;
      padding-left: calcRem(15px);
      padding-right: calcRem(15px);
      padding-top: calcRem(8px);
      padding-bottom: calcRem(8px);
      margin-top: calcRem(5px);
      margin-bottom: calcRem(5px);

      &.out-of-stock {
        background: $lightred;
      }

      &.pre-order {
        background: $presold;
        color: $presoldOrange;
      }

      &+span {
        clear: both;
        display: block;
      }
    }

    h3 {
      font-size: 1.1rem;
      padding-bottom: calcRem(10px) !important;
    }

    &__header {
      font-size: 0.9rem;
      font-weight: 500;
    }

    &__availability {
      font-size: 0.9rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

  
      a {
        color: $orange;
        text-decoration: underline;
      }
    }

    &__price {
      font-size: 0.9rem;
    }
  }

  &__price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__right__details {
      padding-right: calcRem(10px);
    }

    &__right {
      color: $white;

      .amber-circle,
      .red-circle,
      .green-circle {
        margin-right: 0;
        margin-top: 0;
        min-width: calcRem(16px);
        min-height: calcRem(16px);
      }

      /*
      width: 100% !important;
      min-height: calcRem(79px);
      padding: calcRem(15px);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-top: solid 1px $lightgrey;
      border-bottom: solid 1px $lightgrey;
      &__pack {
        font-size: calcRem(14px);
      }
      */

    }
  }

  &__sqm {
    display: flex;
    justify-content: space-between;

    &__desc {

      padding-bottom: calcRem(10px);

      &__top {
        font-weight: $bold;
      }

      &__bottom {
        font-size: calcRem(11px)
      }
    }
  }

  .lw-button {
    border-radius: 0;
  }

  .d-flex {
    width: 100%;
    justify-content: space-between;
  }

  @include media-breakpoint-down(md) {
    .product-card-image {
      display: none !important;

      &.hide-desktop {
        padding: 0 calcRem(20px);
        display: block !important;
      }
    }

    .mobile-cut-summary {
      display: flex;
      justify-content: space-between;
      padding-top: calcRem(30px);
    }

    .availability-row {
      display: flex;
      justify-content: flex-start;
      width: auto;

      >div {
        width: auto;

        &:first-child {
          border-right: 1px solid $lightgrey;
          padding-right: calcRem(40px);
        }

        &:last-child {
          padding-left: calcRem(40px);
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
          flex-direction: column;
          flex-wrap: wrap;

          >div {
            &:first-child {
              border-right: 0;
              padding-right: 0;
            }

            &:last-child {
              padding-left: calcRem(15px);
              padding-top: calcRem(20px);
            }
          }
        }
      }
    }

    .mt-small-3 {
      margin-top: 15px;
    }

    .roll-row {
      .col-md-4 {
        width: 33%;
      }

      .font-bold {
        font-size: 14px;
      }
    }
  }

  &__accordion {
    background-color: white;
    ;
    border-radius: 0;
    padding: 0;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: $darkgrey;
      padding: calcRem(10px) calcRem(30px);
      color: $white;

      &__img__name {
        text-transform: capitalize;
      }

      @include media-breakpoint-down(md) {
        padding: calcRem(10px) calcRem(30px);
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__body {

      padding: calcRem(20px) 0;
      border-right: 1px solid $lightgrey;
      border-bottom: 1px solid $lightgrey;
      border-left: 1px solid $lightgrey;

      .lw-button {
        width: 100%;
        font-size: 1rem;
      }

      .inner {
        padding: 0 calcRem(30px);
      }

      .dropdown,
      .dropdown-toggle {
        border-radius: 0 !important;
      }

      .dropdown {
        border: 1px solid $lightgrey;
      }

      input[type="number"],
      input[type="text"] {
        border: 1px solid $lightgrey;
        border-radius: 0;
        ;
      }
    }

    &__roll {
      &__toggle {
        justify-content: center;
        padding-bottom: calcRem(15px);
      }
    }

  }







  /* order summary */
  &__summary__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid $lightgrey;
    padding-bottom: calcRem(30px);
    padding-top: calcRem(30px);
    padding-left: calcRem(50px);
    position: relative;

    &:first-child {
      padding-top: 0;
      border-top: 0;
    }

    .remove-action {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: none;
      color: $white;
      background: $arrowGrey;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
    }
  }

  &__summary__col__title {
    width: 440%;

  }

  &__summary__col__colour {}

  .summary-content-text {
    font-weight: 500;
    font-size: 0.9rem;
  }

  &__summary__reference {
    width: 35%;
  }

  &__summary__col__subtotal {
    width: 20%;
    margin-right: 0;
  }

  &__summary__delivery {
    width: 45%;

    .summary-content-text {
      padding-left: 5px;
      font-size: 0.75rem;
      color:$textgrey;
    }
  }

  &__summary__width {
    width: 35%;
  }

  &__summary__length {
    width: 20%;
  }

  &__summary__col__title__name {
    font-weight: 500;
    text-transform: capitalize;
  }

  &__summary__col__colour {
    display: block;
    font-size: 1rem;
  }

  &__summary__reference,
  &__summary__col__title,
  &__summary__col__colour,
  &__summary__col__subtotal,
  &__summary__col__reference {
    padding-bottom: calcRem(5px);

    .text-grey {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    &__summary__col__title {
      width: 100%;
      order: 1;
    }

    &__summary__reference {
      width: 50%;
      order: 2;
    }

    &__summary__delivery {
      width: 50%;
      order: 3;
    }

    &__summary__col__subtotal,
    &__summary__area,
    &__summary__length,
    &__summary__width {
      width: 25%;
      padding-top: calcRem(15px);
    }

    &__summary__width {
      order: 4;
    }

    &__summary__length {
      order: 5;
    }

    &__summary__area {
      order: 6;
    }

    &__summary__col__subtotal {
      text-align: right;
      order: 7;
    }
  }
}

.btn-setlength {
  padding: calcRem(5px) calcRem(25px);
  background-color: $lightergrey;
  border: 0;
  margin: 0 5px;
  color:#333 !important;
  &:hover,
  &.active {
    background: $orange;
    color: $white !important;
  }
}

.table--row,
.table--labels {
  display: table;
  width: 100%;

  padding-left: 30px;
  position: relative;

  &__col {
    white-space: nowrap;
  }

  &__col,
  &__title {
    display: table-cell;
    vertical-align: middle;
    width: auto;
    color: $textgrey;
    font-size: 0.9rem;
    padding: 15px 10px;
    box-sizing: border-box;
  }

  &__title__option {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__title__batch {
    width: 20%;
  }

  &__title__width {
    width: 10%;
  }

  &__title__cost {
    width: 25%;
  }

  &__title__delivery {
    width: 25%;
  }

  &__title__stock {
    width: 20%;

    .red-circle,
    .green-circle {
      margin-right: calcRem(5px);
      margin-top: 0;
      padding: 0;
    }
  }
}

.table-contents {
  .inner {
    &:nth-child(odd) {
      background: $lightergrey;
    }
  }
}

.table--row {

  &__title {
    display: none;
  }

  &__col {
    color: $black;
    font-size: 0.8rem;
    text-align: left;
    white-space: nowrap;
  }
}


.calculation {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    width: 50%;

    .font-bold {
      font-weight: normal;
      color: $bordergrey !important;
    }
  }

  &__mid {
    width: calcRem(50px);
    height: 57px;
    line-height: 57px;
    font-size: 30px;
    text-align: center;
    padding: 0 15px;
  }

  &__right {
    width: 50%;
  }
}


@include media-breakpoint-down(md) {
  .table--labels {
    display: none;
  }

  .table--row__title {
    display: block;
  }

  .table--row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0px 0 10px 30px;

    .table--row__col {
      padding-bottom: calcRem(10px);
      padding-top: calcRem(10px);
    }

    .table--row__title {
      padding-bottom: 0;
      padding-left: 0;
      padding-top: 0;
    }

    .table--row__title__batch {
      width: 33%;
      order: 1;
    }

    .table--row__title__delivery {
      width: 33%;
      order: 2;
    }

    .table--row__title__width {
      order: 4;
      width: 33%;
    }

    .table--row__title__length {
      width: 33%;
      order: 5;
    }

    .table--row__title__price {
      width: 33%;
      order: 5;
    }

    .table--row__title__stock {
      width: 33%;
      order: 3;
    }
  }

  .table--row__title__option,
  .table--labels__title__option {
    top: calcRem(10px);
    transform: none;
  }

}

.hide-desktop{
  display: none;
}


@include media-breakpoint-down(sm) {
  .hide-desktop{
    display: block;
  }
  .product-card-ex__summary__col__subtotal{
    width:50%;
    padding-top:calcRem(10px);
  }
  .product-card__summary__status{
    width: 50%;
    position: static;
    order:7;
    padding-top:calcRem(10px);
  }
  .product-card-ex__summary__width,
  .product-card-ex__summary__length,
  .product-card-ex__summary__status{
    width:50%;
  }
  .product-card-ex__summary__delivery,
  .product-card-ex__summary__reference{
    width: 100%;
  }
  .calculation{
    flex-wrap: wrap;
  }
  .calculation__left{
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .calculation__right{
    width:100% !important;
  }
  .calculation__mid{
    display: none !important;
  }
  .table-contents {
    .inner {
      padding: 0 5px;
    }
  }
  .mobile-cut-summary{
    flex-wrap: wrap;
    > div{
      width: 100%;
    }
  }

  .table--row {
    .table--row__title__batch {
      width: 50%;
      order: 1;
    }

    .table--row__title__delivery {
      width: 50%;
      order: 2;
    }

    .table--row__title__width {
      order: 4;
      width: 50%;
    }

    .table--row__title__length {
      width: 50%;
      order: 5;
    }

    .table--row__title__price {
      width: 50%;
      order: 5;
    }

    .table--row__title__stock {
      width: 50%;
      order: 6;
    }
  }
}