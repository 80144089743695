@import "./../../styles/variables";
@import "./../../styles/functions";

.text-input-container {
  border: none;
  width: 100%;


  .addon-wrapper{
    position: relative;
    padding-right: calcRem(60px);
    @media(max-width:400px){
      padding-right: calcRem(40px);
    }
  }
  
  .addon{
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    width:calcRem(60px);
    text-align: center;
    vertical-align: middle;
    line-height:calcRem(60px);
    background: $lightgrey;
    @media(max-width:400px){
      width:calcRem(40px);
    }
  }

  .form-label {
    font-size: $fontSizeSmall;
    padding-left: calcRem(5px);
  }

  .form-control {
    width: 100%;
    height: calcRem(60px);
    border: thin solid transparent;
    color: $darkgrey;
    border-radius: calcRem(30px);
  }

  .error {
    border: 1px solid red !important;
  }

  .error-lbl {
    color: red !important;
  }

  .white {
    background-color: $white;
  }

  .grey {
    background-color: $midgrey;
  }
}
