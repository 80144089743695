@import "./../../styles/variables";
@import "./../../styles/functions";

.modal-content {
  border-radius: calcRem(20px) !important;
}

.modal-header {
  padding: calcRem(20px) calcRem(30px) !important;
}

.modal-body {
  padding: calcRem(20px) calcRem(30px) !important;
}

.lw-modal-close-button {
  transform: translate(calcRem(40px), calcRem(-30px));

  &:hover {
    cursor: pointer;
  }
}
.modal{
  z-index: 9999;
}
.bank-modal {
  z-index: 99999;
  border-radius: calcRem(10px);
  overflow: visible;
  .modal-header {
    background: #5A287D;
    color: #FFF;
  }
  .modal-body{
    padding:0 !important;
    border-radius: calcRem(10px);
    overflow: visible;
  }
  .modal-close{
    transform: none;
    position: absolute;
    top:0;
    right:0;
    transform: translateY(-50%) translateX(50%);
    img{
      transform: none !important;
    }
  }
}