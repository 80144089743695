@import "variables";
@import "global";
@import "./../styles/functions";

.grey-container {
  background-color: $lightergrey;
}

.myaccount {
  padding-top: calcRem(30px);

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calcRem(40px);
  }
  &__content {
    &__nav {

    }
    &__body {
      &__top {
        display: flex;
        align-items: center;
        &__left {
          &__status {
            display: flex;
            justify-content: space-between;
            font-weight: $bold;
            position: relative;
            &__right{
              display: flex;
              justify-content: flex-end;
              font-weight: $bold;
              align-items: center;
              > div{
                padding: 10px;
                position: relative;
                p{
                  padding: 0 5px 10px 5px !important;
                  position: relative;              
                  @include media-breakpoint-down(md) {
                    padding: 0 0px 10px 0px !important;
                    font-size:0.95rem;
                    span{
                      display: none;
                    }
                  }
                }
                &.active p:after{
                  position: absolute;
                  bottom:0;
                  height:3px;
                  content: ''; 
                  display: block;
                  width: 100%;
                  background: $orange;
                  left: 0;
                }
              }
            }
            &__left{
              display: flex;
              justify-content: space-between;
              font-weight: $bold;
              align-items: center;
              margin-left: -15px;
              > div{
                padding: 10px;
                position: relative;
                p{
                  padding: 0 5px 10px 5px !important;
                  position: relative;      
                  @include media-breakpoint-down(md) {
                    font-size:0.95rem;
                    padding: 0 0px 10px 0px !important;
                  }        
                }
                &.active p:after{
                  position: absolute;
                  bottom:0;
                  height:3px;
                  content: ''; 
                  display: block;
                  width: 100%;
                  background: $orange;
                  left: 0;
                }
              }
            }
          }
        }
        &__right {
          display: flex;
          justify-content: flex-end;
          &__search {
            max-width: calcRem(407px);
            width: 100%;
            height: calcRem(60px);
            border: 1px solid $darkgrey;
            background-color: $white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: calcRem(30px);
            &__text {
              padding-left: calcRem(30px);
            }
            &__button {
              padding-right: calcRem(2.5px);
            }
          }
        }
      }
      &__popup {
        width: 100%;
        height: calcRem(70px);
        background-color: $white;
        border-bottom: 4px solid $orange;
        margin-top: calcRem(20px)!important;
        display: flex;
        align-items: center;
        &__warning {
          padding-left: calcRem(10px);
        }
        &__text {
          padding-left: calcRem(15px);
        }
      }
      &__count {
        display: flex;
        justify-content: space-between;
        padding-top: calcRem(40px);
        padding-bottom: calcRem(30px);
      }
    }
  }
}

.contact-bottom {
  padding: calcRem(80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title h3 {
    font-size: calcRem(23px) !important;
    font-weight: $bold;
    padding-bottom: calcRem(12px) !important;
    text-align: center;
  }
  &__desc p {
    padding-bottom: calcRem(30px) !important;
    text-align: center;
  }
  &__cta {
    display: flex;
    &__left {
      padding-right: calcRem(12px);
    }
  }
}

@include media-breakpoint-down(md) {
  .order-summary__table__content{
    justify-content: space-between;
    > div{
      width: auto !important;
      padding-bottom:10px;
      max-width: 50%;
      .product-row{
        .text-grey-small{
          font-size: 0.75rem  !important;
        }
        .text-grey-smaller{
          font-size: 0.75rem  !important;
          white-space: nowrap;
        }
      }
    }
  }
  .order-summary{
    .row .row{
      position: relative;
      .text-end{
        position: absolute;
        top:0;
        left:0;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .myaccount__content__body__top__left__status{
    flex-wrap: wrap;
  }
  .myaccount__content__body__top__left__status__right,
  .myaccount__content__body__top__left__status__left{
    margin-left: 0;
      flex-wrap: wrap;
      > div{
        width: 100%;
      }
  }
  .date-separator{
    display: block;
    height: 0;
    overflow: hidden;
    margin-bottom: 5px;
  }

}
