@import "./../../styles/variables";
@import "./../../styles/functions";

.menu-bottom {
  height: calcRem(81px);
  border-radius: calcRem(40.5px);
  width: 100%;
  background-color: $white;
  -webkit-box-shadow: 2px 12px 8px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 2px 12px 8px 5px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 99999;
}

.footer-mobile-nav {
  display: none;
  z-index: 9999;
  @include media-breakpoint-down(md) {
    display: block;
    position: fixed;
    bottom: 1rem;
    width: 100%;
    margin-bottom: 0;
    margin-bottom: constant(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-top);
  }
}



