@import "./../../styles/variables";
@import "./../../styles/functions";

.select-bank{
    h3{
        padding-bottom:30px !important;
        font-size: 1.4rem;
        text-align: left;
    }
    img{
        max-width: 100%;
    }
}

@keyframes l4 {to{transform: rotate(1turn)}}

.the-loader{
    /* HTML: <div class="loader"></div> */
    text-align: center;
    display: flex;
    justify-content: center;
    padding:15px 0 0 0;
    .loader {
        width: 50px;
        --b: 8px; 
        aspect-ratio: 1;
        border-radius: 50%;
        padding: 1px;
        background: conic-gradient(#0000 10%,$orange) content-box;
        -webkit-mask:
        repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
        radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
        -webkit-mask-composite: destination-in;
                mask-composite: intersect;
        animation:l4 1s infinite steps(10);
    }
}

.anti-fraud{
    font-weight: bold;
}
.custom-modal-body{
    position: relative;
}
.show-consent-inner{
    background: #FFF;
    border-radius: 8px;;
    padding:15px;
}
.show-consent-overlay{
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 30px;
    color: #333;
}
.pay-by-bank-title{
    font-size: 1.4rem;
}
.payit-small{
    font-size: 0.7rem;
}
.select-bank-header{
    background-color: #5A287D;
    padding: calcRem(20px) calcRem(30px);
    color:#FFF;
    svg{
        width: 130px;
    }
}
.custom-modal-body{
    padding:calcRem(30px);
}
.bank-item{
    border-bottom: 1px solid #EFEFEF;
    padding:10px 15px;
    img{
        width:50px;
        margin-right: 20px;
    }
    font-weight: bold;
    cursor: pointer;
    &.item-selected, 
    &:hover{
        background: #EFEFEF;
    }
}
.bank-list{
    max-height: 400px;
    overflow: auto;
}