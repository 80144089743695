@import "../../styles/variables";
@import "../../styles/global";
@import "../../styles/functions";

.sub-title {
  font-size: $fontSizeMedium;
  font-weight: $regular;
}
.main {
  // padding: calcRem(48px);
  @include media-breakpoint-down(md) {
    padding: 0;
    padding-bottom: 10vh;
  }
  
  .title {
    font-size: $fontSizeMedium;
    font-weight: $bold;
  }
}
.CookieConsent{
  box-sizing: border-box;
  @include media-breakpoint-down(md) {
      bottom:calc(81px + 1rem) !important;   
      margin-bottom: constant(safe-area-inset-top);
      margin-bottom: env(safe-area-inset-top); 
  }
  a{
    color:#FFF;
    text-decoration: underline;
    &:hover{
      color:#FFF;
      text-decoration: none;
    }
  }
}