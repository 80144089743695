@import './../../styles/variables';
@import './../../styles/functions';

.auto-suggest{
  background-color: #FFF;
  padding:0;
  top:90%;
  border: 1px solid $lightgrey;
  left: calcRem(25px);
  width: auto;
  min-width: calcRem(600px);
  max-height: 200px;
  overflow-y: auto;
  display: inline-block;
  position: absolute;
  z-index: 999;
  border-radius: calcRem(5px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  @include media-breakpoint-down(md) {
   max-width: 90%;
   min-width: 90%;;
  }
  .auto-suggest-item{
    padding: calcRem(10px) calcRem(20px);
    cursor: pointer;
    &:hover{
      background-color: $lightergrey;
    }
  }
}

