@import "./../../styles/variables";
@import "./../../styles/functions";

.l-card {
  position: relative;
  width: 100%;
  min-height: calcRem(380px);
  max-width: calcRem(380px);
  // width: 100%;
  border-radius: calcRem(15px);
  padding: calcRem(40px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include media-breakpoint-down(md) {
    padding: calcRem(20px);
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    cursor: pointer;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: calcRem(24px);
    object-fit: cover;
    background-position: center;
  }

  &__details {
    z-index: 99;
    padding-bottom: calcRem(20px);
    &__title {
      font-size: $fontSizeMediumLarge;
      color: $white;
      line-height: calcRem(40px);
      padding-bottom: calcRem(25px);
    }
  }
}

