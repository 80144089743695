@import "./../../styles/variables";
@import "./../../styles/functions";

.card-wide {
  height: calcRem(127.7px);
  max-width: calcRem(691px);
  padding: calcRem(40px);
  padding-right: calcRem(10px);
  background-color: $white;
  border-radius: calcRem(24px);
  margin-bottom: calcRem(30px);
  -webkit-box-shadow: 2px 12px 8px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 2px 12px 8px 5px rgba(0, 0, 0, 0.03);
  @include media-breakpoint-down(sm) {
    .card-wide {
      padding: calcRem(20px);
    }
    .card-wide__footer__price-button {
      padding: 0px calcRem(10px);
      height: calcRem(32px);
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__header {
    display: flex;
    align-items: flex-end;
    padding-bottom: calcRem(10px);
    &__check {
      margin-right: calcRem(20px);
    }
    &__title h1 {
      color: $darkgrey;
      font-size: $fontSizeSmall;
      font-weight: $bold;
      margin: 0;
    }

  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    color: $darkgrey;
    font-size: calcRem(15px);
    &__price-button {
      color: $white;
      height: calcRem(32px);
      background-color: $orange;
      border-radius: calcRem(7px);
      border: none;
    }
  }
}



