@import "./../../styles/variables";
@import "./../../styles/functions";

.card-order-full {
  background-color: #f8f8f8;
  margin-bottom: 10px;
  padding: 20px 30px;
  width: 100%;

  .card-order-full-cell-title {
    font-weight: bold;
    padding-bottom: 5px;
  }

  .card-order-full-title {
    h2 {
      color: $darkgrey;
      font-size: calcRem(22px);
      font-weight: $bold;
      margin: 0;
    }
  }

  .card-order-full-details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: calcRem(15px);
  }

  .card-order-full-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .card-order-full-summary,
    .card-order-full-show-lines {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .card-order-full-lines {
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;

    .card-order-full-cell {
      padding: 5px;
    }
  }
}