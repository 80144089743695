.product-card__pricing {

    div {
        font-size: 13.5px;
    }

    .badge {
        font-size: 11px !important;
        padding: 3px 8px !important;
    }

    .product-card__availability_row {
        display: flex;

        @media (max-width:990px) {
            flex-wrap: wrap;
        }



        >div {
            width: 50%;

            &:first-child {
                padding-right: 5px;
            }

            &:last-child {
                padding-left: 5px;
            }

            @media (max-width:990px) {
                width: 100%;

                &:first-child {
                    padding-right: 0;
                }

                &:last-child {
                    padding-left: 0;
                }
            }
            @include media-breakpoint-down(sm) {
                & + div{
                    margin-top:10px;
                }
              }
        }

    }

    .product-card__pricing__header {
        padding: 5px 8px;
        background-color: black;
        color: white;
    }

    .product-card__pricing__content {
        margin-top: 5px;
        padding: 5px 8px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        @include media-breakpoint-down(xxl) {
            padding: 5px 3px;
        }
    
        .product-card__pricing__prices {
            width: 65%;

            @include media-breakpoint-down(xxl) {
                width: auto;
            }
        }

        .product-card__pricing__availability {
            width: 35%;
            display: flex;
            justify-content: flex-end;
            @include media-breakpoint-down(xxl) {
                width: auto;
            }
            > div{
                display: flex;
            flex-wrap: nowrap;
            }
        }
    }

    .product-card__pricing__view_availability {
        .product-card-ex__pricing__availability {
            padding: 5px 8px;
        }


        @include media-breakpoint-down(md) {
            padding-left: calcRem(8px);
            padding-bottom: calcRem(15px);
        }
        
        a {
            margin-top: 10px;
            display: block;
            width: 100%;
            background-color: $orange;
            color: white;
            padding: 5px 5px;
            text-decoration: none;
            font-size: 13px;
        }
    }

    .product-card__pricing__price_per_pack {
        padding: 10px 0;
    }
}